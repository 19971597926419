import {Position} from "../../models/position";
import {SET_POSITIONS, SET_POSITION} from "../../constants/constants";

export const setPositions = (positions: Position[]) => {
    return {
        type: SET_POSITIONS,
        positions: positions
    }
}

export const setPosition = (positions: Position[]) => {
    return {
        type: SET_POSITION,
        positions: positions
    }
}
