import {OpenOrder} from "../../models/openOrder";
import {SET_OPENORDERS, SET_OPENORDER} from "../../constants/constants";


const setOpenOrdersReducer = (state = {open_orders: []}, action: { type: string, open_orders: OpenOrder[]}) => {
    switch (action.type) {
        case SET_OPENORDERS:
            return {
                ...state,
                open_orders: action.open_orders
            };
        case SET_OPENORDER:
            return {
                ...state,
                open_orders: [...state.open_orders, action.open_orders]
            };
        default:
            return state;
    }
}

export default setOpenOrdersReducer;