import {OpenOrder} from "../../models/openOrder";
import {SET_OPENORDERS, SET_OPENORDER} from "../../constants/constants";

export const setOpenOrders = (open_orders: OpenOrder[]) => {
    return {
        type: SET_OPENORDERS,
        open_orders: open_orders
    }
}

export const setOpenOrder = (open_orders: OpenOrder[]) => {
    return {
        type: SET_OPENORDER,
        open_orders: open_orders
    }
}
