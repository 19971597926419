import React, {useState} from 'react';
import axios from 'axios';
import Modal from './Modal';
import {Position} from "../models/position";
import alertMessage from "./Alert";

interface VariablesProps {
    platform: string;
    position: Position;
    availableBalance: number;
    setNewOpenOrder?: any;
    updatePosition?: any;
    setError: any;
    error: string;
    client_id?: number;
    getStatus?: any;
}

const ClosePositionBy: React.FC<VariablesProps> = (props) => {
    const {platform, position, availableBalance, setNewOpenOrder, updatePosition, setError, error, client_id, getStatus} = props;
//     const [error, setError] = useState('');
    const [showModalCloseBy, setShowModalCloseBy] = useState(false);

    const [orderSymbol, setOrderSymbol] = useState('');
    const [orderType, setOrderType] = useState('');
    const [side, setSide] = useState('');
    const [size, setSize] = useState('');
    const [sizeConst, setSizeConst] = useState(0);
    const [percentSize, setPercentSize] = useState(100);
    const [lastPrice, setLastPrice] = useState('');

    const last_price = async (symbol: string) => {
        let endpoint = `${platform}/futures/price/${symbol}/`
        if (client_id){
                endpoint = `${platform}/futures/price/${symbol}/${client_id}/`
            }
        try {
            const response = await axios.get(`${endpoint}`);
            setLastPrice(response.data.data.price)
        } catch (e:any) {
            setError(e.message)
          }
    }

    const orderConfirmation = async () => {
        const percent = (Number(size)*Number(lastPrice)/Number(availableBalance)*100).toFixed(2)
        const take_profit = 0
        const stop_loss = 0
        let endpoint = `${platform}/futures/order/${orderSymbol}/`
        if (client_id){
                endpoint = `${platform}/futures/order/${orderSymbol}/${client_id}`
            }
        setShowModalCloseBy(false)
        try {
            const response = await axios.post(`${endpoint}`, {
                order_type: orderType,
                side: side,
                price: lastPrice,
                size: size,
                take_profit: take_profit,
                stop_loss: stop_loss,
                reduceOnly: true,
                percent: percent,
                close_by_client: true,
                percentSize: percentSize,
            });

            console.log('order: ',response.data);
            if (response.data.trader.orderId) {
                alertMessage('success', `${response.data.trader.type}, ${response.data.trader.symbol}, ${response.data.trader.side},
                    ${response.data.trader.origQty} ${response.data.trader.symbol.replace('USDT', '')},
                    ${response.data.trader.price} USDT`
                );
                if (response.data.trader.status === "NEW"){
                    if (response.data.trader.type==="LIMIT") {
                        if (client_id){
                                getStatus(client_id, platform)
                            } else {
                                    setNewOpenOrder(response.data.trader);
                                }
                    }
                    if (response.data.trader.type==="MARKET") {
                        if (client_id){
                                getStatus(client_id, platform)
                            } else {
                                    updatePosition();
                                }
                    }
                }
            }
            else {
                alertMessage('error', `${response.data.trader.msg}`)
            }
            if (response.data.subscribers.count_subscribers === 0) {
                alertMessage('warn', `Subscribers: ${response.data.subscribers.count_subscribers}`);
            }
            if (response.data.subscribers.count_subscribers !== response.data.subscribers.orders.length) {
                alertMessage('warn', `Ордера НЕ исполнены у ${response.data.subscribers.count_subscribers-response.data.subscribers.orders.length}
                    подписчиков из ${response.data.subscribers.count_subscribers}!`);
            }
            if (response.data.subscribers.orders.length) {
                if (response.data.subscribers.count_subscribers === response.data.subscribers.orders.length) {
                    const dateTime = (time: number) => {
                        return new Intl.DateTimeFormat('en-US', {year: 'numeric', month: '2-digit',day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: false,}).format(time)
                    }
                    const msg = () => {
                        let orders = ''
                        response.data.subscribers.orders.map(
                            (order:any) => {
                                orders = `${orders} ${dateTime(order.updateTime)}, ${order.symbol}, ${order.side}, ${order.origQty}, ${order.price} USDT`
                            }
                        )
                        return orders
                    }

                    alertMessage('success', `Ордера исполнены у всех ${response.data.subscribers.count_subscribers} подписчиков!`);
                    alertMessage('success', `${msg()}`);
                }
            }

        } catch (e:any) {
            alertMessage('error', `${e.message}`)
            setError(e.message)
            console.log('errorOpenOrder:', error)
            console.log('errorOpenOrder', e)
          }

    }

    const show_confirm_close_by = () => {
        const border_red = {
            border: '1px solid rgba(255, 0, 0, 1.0)',
            cursor: 'pointer',
        };
        const border_none = {
            border: '0px',
            cursor: 'pointer',
        };

        const edit_size = (percent: number) => {
            let newSize: number = sizeConst*percent/100
            setPercentSize(percent)
            setSize(newSize.toString())
        }

        return (
             <div className="container">
	            <h5>{orderType} Close</h5>
                <hr/>
	            <div className="row">
                    {(orderType==='LIMIT') && (
                        <div className="mb-3">
                            <label className="form-label">Closing Price USDT</label>
                            <input className="form-control"
                                name="price"
                                type="number"
                                value={lastPrice}
                                onChange={e => setLastPrice(e.target.value)}
                                autoComplete="off"
                                min="0"
                                step="0.0000001"
                            />
                        </div>
                    )}
	                <div className="mb-3">
                        <label className="form-label">Closed Qty {orderSymbol.replace('USDT','')}</label>
                        <input className="form-control"
                            name="size"
                            type="number"
                            value={size}
                            onChange={e => setSize(e.target.value)}
                            autoComplete="off"
                            min="0"
                            step="0.01"
                            max={sizeConst}
                        />
                        Cost: {(Number(size)*Number(lastPrice)).toFixed(2)} USDT
                    </div>

                    <div className='col' style={(percentSize === 10) ? border_red: border_none} onClick={()=>edit_size(10)}>10%</div>
                    <div className='col' style={(percentSize === 25) ? border_red: border_none} onClick={()=>edit_size(25)}>25%</div>
                    <div className='col' style={(percentSize === 50) ? border_red: border_none} onClick={()=>edit_size(50)}>50%</div>
                    <div className='col' style={(percentSize === 75) ? border_red: border_none} onClick={()=>edit_size(75)}>75%</div>
                    <div className='col' style={(percentSize === 100) ? border_red: border_none} onClick={()=>{setSize(sizeConst.toString()); setPercentSize(100)}}>100%</div>

	            </div>
	            <p></p>
                <button className="button_long" onClick={()=>orderConfirmation()}>Confirm</button> &nbsp;&nbsp;
                <button className="button_grey" onClick={() => setShowModalCloseBy(false)}>Cancel</button>

            </div>
        )
    }
    const closePositionBy = (symbol: string, positionAmt: number, order_type: string, side: string) => {
        setShowModalCloseBy(true)
        setOrderSymbol(symbol)
        setOrderType(order_type)
        setSize(positionAmt.toString())
        setSizeConst(positionAmt)
        if (side === 'Buy'){setSide('Sell')}
        if (side === 'Sell'){setSide('Buy')}
        setPercentSize(100)
        last_price(symbol)
    }
    return (
        <>
            <button type="button" className="btn btn-secondary btn-sm" onClick={()=>closePositionBy(position.symbol, position.positionAmt, 'LIMIT', position.side)}>Limit</button>&nbsp;
            <button type="button" className="btn btn-secondary btn-sm" onClick={()=>closePositionBy(position.symbol, position.positionAmt, 'MARKET', position.side)}>Market</button>

        {showModalCloseBy && (
                <Modal
                    active={showModalCloseBy}
                    setActive={setShowModalCloseBy}
                >
                    {show_confirm_close_by()}
                </Modal>
        )}
        </>
    )
}
export default ClosePositionBy;