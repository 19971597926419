import React from 'react';
import {Link} from 'react-router-dom';

interface ISymbol {
    id: number;
    symbol: any;
    platform: string;
}

interface SymbolsListProps {
    platform: string;
    symbols: ISymbol[];
}

interface ISymbolItem extends ISymbol{ }

const SymbolItem: React.FC<ISymbolItem> = (props) => {
    const {platform, id, symbol} = props;
    const path = "/futures/"+platform+'/'+symbol
//     alert(symbol);
    return (
        <div >
            <li key={id} className="nav-item">
                <Link to={path}>{symbol}</Link>
            </li>
        </div>
    )
}

const Symbols: React.FC<SymbolsListProps> = (props) => {
// const Symbols: React.FC<{symbols:any}> = (props) => {

//     console.log('Symbols', props.symbols);
    return (

        <div className="offcanvas-body d-md-flex flex-column p-0 pt-lg-3 overflow-y-auto">
          <ul className="nav flex-column">
            {
                props.symbols.map((isymbol) => <SymbolItem platform={props.platform} id={isymbol.id} symbol={isymbol.symbol}/>)
            }
          </ul>
        </div>
    )
}

export default Symbols;