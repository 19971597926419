import React, {Component} from 'react';
import axios from "axios";

class TestConnect extends Component<{id: number, endpoint: string}> {
    state = {
        testOk: false,
        msg: 'Error connect',
        balance: 0,
    }
//     componentDidMount = async () => {
//         console.log('endpoint ', this.props.endpoint)
//     }
    test = async () => {

        const response = await axios.get(`${this.props.endpoint}/${this.props.id}`);
//         console.log('response: ', response)
        if (response.data.balance >= 0){
            this.setState({
                testOk: true,
                msg: 'Ok',
                balance: response.data.balance
            })
        } else {
            this.setState({
                testOk: true,
            })
        }

    }
    res() {
        if (this.state.testOk)
            return <span className={(this.state.msg==='Ok') ? "green" : "red" }>
                        {this.state.msg} {(this.state.balance) ? `Balance: ${this.state.balance}` : null }
                   </span>
    }
    render() {

        return (
        <>
            <a href="#test_connect" onClick={() => this.test()}>TestConnect</a> &nbsp; | &nbsp;
            {this.res()}
        </>
        );
    }
}

export default TestConnect;