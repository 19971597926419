import React from 'react';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const alertMessage = (type: string, message: string) => {
        const notify = (type: string, message: string) => {
            if (type === 'success'){
                toast.success(message, {
                    position: toast.POSITION.BOTTOM_RIGHT
                });
            }
            if (type === 'error'){
                toast.error(message, {
                    position: toast.POSITION.BOTTOM_RIGHT
                });
            }
            if (type === 'warn'){
                toast.warn(message, {
                    position: toast.POSITION.BOTTOM_RIGHT
                });
            }
        }
        return notify(type, message)
    }
export default alertMessage;