import React, { useEffect, useState } from 'react';
import axios from 'axios';

type FuturesTradeState = {
    symbol: string,
    price: number,
    time: string
}

const DataSymbol: React.FC<{platform: any, symbol: any, price: number, markPrice: number}> = (props) => {
    const {platform, symbol, price, markPrice} = props;
//     const [symbol_info, setData] = useState<FuturesTradeState>();
    const [error, setError] = useState('');

//     const symbolInfo = async (platform: string, symbol: string) => {
//         try {
//             const response = await axios.get(`${platform}/futures/price/${symbol}/`);
//
//             if (response.data.data){
//                 setData(response.data.data)
//             }
//         } catch (e:any) {
//                 setError(e.message)
//                 console.log(e.message)
//           }
//     }
//     useEffect(()=> {
//         symbolInfo(platform, symbol);
//     }, [symbol]);

    if (symbol) {
        return (
            <div className="container text-center float-start">
                <div className="row align-items-start">
                    <p></p>
                    <div className="col">
                        {symbol}
                    </div>
                    <div className="col">
                        Price <br/>{price}
                    </div>
                    <div className="col">
                        MarkPrice <br/>{markPrice}
                    </div>
                    <div className="col">

                    </div>
                    <div className="col">

                    </div>
                    <div className="col">

                    </div>
                    <div className="col">

                    </div>
                </div>
            </div>

        )
    }
    if (error) {
        return (
            <div className="error">
               {error}
            </div>
        )
    }
    return (null)
}

export default DataSymbol;