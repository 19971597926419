import React, {Component, Dispatch, PropsWithChildren} from 'react';
import Nav from "./Nav";
import Menu from "./Menu";
import axios from 'axios';
import {Navigate} from 'react-router-dom';
import {connect} from "react-redux";
import {User} from "../models/user";
import setUser from "../redux/actions/setUserAction";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

class Wrapper extends Component<PropsWithChildren<any>> {
    state = {
        redirect: false
    }
    componentDidMount = async () => {
        try {
            const response = await axios.get('user');
            const user: User = response.data.data;

            this.props.setUser(new User(
                user.id,
                user.first_name,
                user.email,
                user.role,
                user.permissions,

            ));

        } catch (e) {

            this.setState({
                redirect: true
            })
        }
    }
    render() {
        if (this.state.redirect) {
            return <Navigate to={'/login'}/>;
        }
        return (
        <>
            <Nav/>
            <div className="container-fluid">
              <div className="row">
                 <div className="sidebar border border-right col-md-3 col-lg-2 p-0 bg-body-tertiary">
                    <div className="offcanvas-md offcanvas-end bg-body-tertiary" id="sidebarMenu" aria-labelledby="sidebarMenuLabel">
                        <Menu role={this.props.user.user.role}/>
                    </div>
                 </div>
                 <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
                   {this.props.children}
                 </main>
              </div>
            </div>
            <ToastContainer/>
        </>
        );
    }
}

const mapStateToProps = (state: { user: User }) => {
    return {
        user: state.user
    };
}

const mapDispatchToProps = (dispatch: Dispatch<any>) => {
    return {
        setUser: (user: User) => dispatch(setUser(user))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Wrapper);