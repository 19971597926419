import {Position} from "../../models/position";
import {SET_POSITIONS, SET_POSITION} from "../../constants/constants";


const PositionsReducer = (state = {positions: []}, action: { type: string, positions: Position[]}) => {
//     console.log('action PositionsReducer: ',action);
    switch (action.type) {
        case SET_POSITIONS:
            return {
                ...state,
                positions: action.positions
            };
        case SET_POSITION:
            return {
                ...state,
                positions: [...state.positions, action.positions]
            };
        default:
            return state;
    }
}

export default PositionsReducer;