import {createStore, combineReducers} from 'redux';
import setUserReducer from "./reducers/setUserReducer";
import setOpenOrdersReducer from "./reducers/setOpenOrdersReducer";
import PositionsReducer from "./reducers/PositionsReducer";

const rootReducer = combineReducers ({
    user: setUserReducer,
    open_orders: setOpenOrdersReducer,
    positions: PositionsReducer,
})

const configureStore = () => {
    return createStore(rootReducer);
}

export default configureStore;