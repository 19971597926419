import React, { useEffect, useState } from 'react';
import axios from 'axios';

interface Bracket {
    bracket: string;
    initialLeverage: string;
}
const LeverageBrackets = (props: any) => {
    const {changeLeverage, leverage, platform, symbol} = props
    const [brackets, setBrackets] = useState([]);
    const border_red = {
            border: '1px solid rgba(255, 0, 0, 1.0)',
            cursor: 'pointer',
    };
    const border_none = {
            border: '0px solid rgba(255, 0, 0, 1.0)',
            cursor: 'pointer',
    };
    const getLeverageBrackets = async (symbol:string) => {
        const response = await axios.get(`${platform}/futures/leverage/${symbol}`);
        if (platform === 'binance'){
            console.log(response.data.leverage[0].brackets)
            setBrackets(response.data.leverage[0].brackets.reverse());
        }
        if (platform === 'bybit'){
//             console.log(response.data)
            setBrackets(response.data.leverage);
        }
    }

    useEffect(()=> {
        if (symbol) {
            getLeverageBrackets(symbol);
        }
    },[]);
    if (brackets.length) {
        return (
            <>
                {
                    brackets.map((bracket: Bracket)=>
                    <span key={bracket.bracket}
                        style={bracket.initialLeverage == leverage ? border_red: border_none}
                        onClick={() => changeLeverage(bracket.initialLeverage)}>{bracket.initialLeverage} </span>
                )
                }
            </>
        )
    }
    return (
    <div className="text-center">
      <div className="spinner-border" role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
    </div>
    )

}

export default LeverageBrackets;