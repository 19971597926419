import React, {Component, SyntheticEvent} from 'react';
import Wrapper from '../../components/Wrapper';
import {connect} from "react-redux";
import {User} from "../../models/user";
import {SubscriptionsTrading} from "../../models/subscriptions";
import axios from 'axios';
import {Navigate, Link} from 'react-router-dom';
import Deleter from "../../components/Deleter";
import TestConnect from "../../components/TestConnect";
import SendSelect from "../../components/Select";
import EditAPIKeys from "../../components/EditAPIKeys";

type typeService = {
    id: string,
    name: string,
}

type typeClient = {
    id: string,
    email: string,
}

type typeApiKeys = {
    id: string,
}

type selectOptions = {
    value: string,
    label: string,
}

class Subscriptions extends Component<{user: User}> {

    state = {
        serviceName: '',
        api_keys: '',
        client: '',
        add_link: true,
        add_form: false,
        subscriptions: [],
        error: '',
        optionsServiceName: [],
        optionsClient: [],
        optionsApiKeys: [],
        selectApiKeys: false,
        defaultOptionApi: null,
    }
    componentDidMount = async () => {
        try {
            const response = await axios.get(`binance/futures/subscriptions/`);
            this.setState({
                subscriptions: response.data,
                error: ''
            })

        } catch (e:any) {
                console.log("Error:", {e})
                if (e.message.includes("403")){
                    this.setState({
                        error: '403 Forbidden'
                    })
                }
        }
    }

    submit = async (e: SyntheticEvent) => {
        e.preventDefault();
        try {
            const response = await axios.post('binance/futures/subscriptions/', {
                serviceName: this.state.serviceName,
                client: this.state.client,
                api_keys: this.state.api_keys,
            });
            if (response.data.msg != undefined){
                if (response.data.msg.includes('This record is already in the database!')){
                    this.setState({
                            error: 'This record is already in the database!'
                        })
                }
            }
             else
                {
                    this.setState({
                    subscriptions: [...this.state.subscriptions, response.data],
                    error: '',
                    })
                    this.link_form();
                }

         } catch (e:any) {
                console.log("Error:", {e})
                if (e.message.includes("403")){
                    this.setState({
                        error: '403 Forbidden'
                    })
                } else {
                    this.setState({
                        error: 'Invalid or non-unique entered data, please try again!'
                    })
                }
            }
    }

    handleDelete = async (id: number) => {
        this.setState({
            subscriptions: this.state.subscriptions.filter((subscr: SubscriptionsTrading) => subscr.id !== id)
        })
    }

    actions = (trading_platform: string, id: number, clientId: number) => {
//         const first_world = platform.match(/(\w+)/)
        const platform = trading_platform.toLowerCase().split(' ')[0]
        if (this.props.user.role) {
            return (
                <div className="btn-group mr-2">
                    <Deleter id={id} endpoint={`${platform}/futures/subscriptions`}
                        handleDelete={this.handleDelete}
                    />
                    &nbsp; | &nbsp;
                    <TestConnect
                        id={clientId}
                        endpoint={`${platform}/futures/account_info/BTCUSDT`}
                    />
                </div>
            )
        }
    }

    getOptions = async () => {
        try {
            let items: selectOptions[] = []

            const responseService = await axios.get('binance/futures/subscriptions/options/');
            if (responseService.data.length) {
                responseService.data.map(
                    (service: typeService) => {
                         let item: selectOptions = {
                            value: service.id,
                            label: service.name
                         }
                         items = [...items, item]
                    }
                )
                this.setState({
                    optionsServiceName: items,
                })
                items = []
            }

            const responseClient = await axios.get('clients');
            if (responseClient.data.length) {
                responseClient.data.map(
                    (client: typeClient) => {
                        let item: selectOptions = {
                            value: client.id,
                            label: client.email
                        }
                        items = [...items, item]
                    }
                )
                this.setState({
                    optionsClient: items,
                })
            }

        } catch (e:any) {
                console.log("Error:", {e})
                if (e.message.includes("403")){
                    this.setState({
                        error: '403 Forbidden'
                    })
                } else {
                    this.setState({
                        error: e.message
                    })
                }
            }
    }
    getOptionsApiKeys = async (platform_id: string, clientId: string) => {
        try {
            let items: selectOptions[] = []

            const responseApiKeys = await axios.get(`client_api/${platform_id}/${clientId}`);
            if (responseApiKeys.data.id){
                this.setState({
                    optionsApiKeys: [{value: responseApiKeys.data.id, label: responseApiKeys.data.id}],
                    defaultOptionApi: [{value: responseApiKeys.data.id, label: responseApiKeys.data.id}]
                })
            }

            if (responseApiKeys.data.length) {
                responseApiKeys.data.map(
                    (api_keys: typeApiKeys) => {
                         let item: selectOptions = {
                            value: api_keys.id,
                            label: api_keys.id
                         }
                         console.log('item',item)
                         items = [...items, item]
                    }
                )
                this.setState({
                    optionsApiKeys: items,
                })
                items = []
            }


        } catch (e:any) {
                console.log("Error:", {e})
                if (e.message.includes("403")){
                    this.setState({
                        error: '403 Forbidden'
                    })
                } else {
                    this.setState({
                        error: e.message
                    })
                }
            }
    }
    link_form = async () => {
        let add_link = this.state.add_link
        let add_form = this.state.add_form
        add_link = add_link ? false: true
        add_form = add_form ? false: true
        this.setState({
          add_link: add_link,
          add_form: add_form,
          error: '',
          selectApiKeys: false

        })
        if (add_form){
            await this.getOptions();
        }
	}

	addSelectApi = () => {
	    let selectApiKeys = this.state.selectApiKeys
        selectApiKeys = selectApiKeys ? false: true
	    this.setState({
	        selectApiKeys: selectApiKeys
	    })
	}
    setClearSelectApi = (name: string) => {
        if ((name==='client') || (name==='serviceName')){
            this.setState({
                selectApiKeys: false
            })
        }
    }
    handleChange = async (name: string, e: any) => {
        this.setState({
            defaultOptionApi: null
        })

        if ((this.state.selectApiKeys) && (!e) && (name === 'client')){
                this.addSelectApi()
        }

        if (e){
            this.setState({[name]: e.value})
            if (name === 'client'){
                this.setState({optionsApiKeys: []})
                if (!this.state.selectApiKeys){
                    this.addSelectApi()
                }

                this.getOptionsApiKeys(this.state.serviceName, e.value);
            }

        }
    }
    addLinkForm = () => {
        if (this.props.user.role && this.state.add_link)
            return (
                <>
                    <Link className="nav-link d-flex align-items-center gap-2" to="#add-client" onClick={this.link_form}>
                        Add subscriptions
                    </Link>
                    <p></p>
                </>
            )
        if (this.props.user.role && this.state.add_form)
            return (
                <>
                    <form className="container" onSubmit={this.submit}>
                        <h4 className="h5 mb-3 fw-normal">Please enter data client below</h4>
                        <div className="row mb-2">
                            <SendSelect
                                name="serviceName"
                                options={this.state.optionsServiceName}
                                setChange={this.handleChange}
                                placeholder="Select Service"
                                setClearSelectApi={this.setClearSelectApi}
                            />
                        </div>
                        <div className="row mb-2">
                            <SendSelect
                                name="client"
                                options={this.state.optionsClient}
                                setChange={this.handleChange}
                                placeholder="Select Client"
                                setClearSelectApi={this.setClearSelectApi}
                            />
                        </div>
                        {this.state.selectApiKeys &&
                            <div className="row mb-2">
                                <SendSelect
                                    name="api_keys"
                                    options={this.state.optionsApiKeys}
                                    setChange={this.handleChange}
                                    placeholder="Select API keys"
                                    setClearSelectApi={this.setClearSelectApi}
                                />
                            </div>
                        }

                        <div className="form-error">
                        {this.state.error}
                        </div>
                        <button className="btn btn-primary w-20 py-2" type="submit">Save</button>&nbsp;&nbsp;
                        <button className="btn btn-secondary w-20 py-2" onClick={this.link_form}>Cancel</button>
                        <p></p>
                    </form>
                </>
            )
    }

    render() {

        if (!this.props.user.role) {
            return <Navigate to='/my/dashboard'/>
        }
        if (this.state.error.includes('403')){
            return <Navigate to='/login'/>
        }

        return (
            <Wrapper>
                <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                    <h1 className="h2">Subscriptions</h1>
                </div>
                {this.addLinkForm()}

                <div className="table-responsive small">
                {this.state.subscriptions.length >0  && (
                    <table className="table table-striped table-sm">
                      <thead>
                        <tr>
                          <th scope="col">ID</th>
                          <th scope="col">Service name</th>
                          <th scope="col">Client (UID)</th>
                          <th scope="col">ID Api Keys</th>
                          <th scope="col">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.subscriptions.map(
                            (subscr: SubscriptionsTrading) => {
                                return (
                                    <tr key={subscr.id}>
                                        <td>{subscr.id}</td>
                                        <td>{subscr.serviceName} </td>
                                        <td>{subscr.client} ({subscr.client_id}) </td>
                                        <td>{subscr.api_keys} <EditAPIKeys api_keys_id={subscr.api_keys} platform={subscr.serviceName} client={subscr.client}/></td>
                                        <td>{this.actions(subscr.serviceName, subscr.id, subscr.client_id)}</td>
                                    </tr>
                                )
                            }
                        )}
                      </tbody>
                    </table>
                )}
                {!this.state.subscriptions.length && <span>Client api keys are missing!</span>}

                </div>


            </Wrapper>
        )
    }
}

// @ts-ignore
export default connect(state => ({user: state.user.user}))(Subscriptions);