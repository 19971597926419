import React from 'react';
import './App.css';
import Dashboard from "./pages/Dashboard";
import ApiKeys from './pages/apikeys/ApiKeys';
import ApiClientsKeys from './pages/apikeys/ApiClientsKeys';
import Settings from './components/Settings';
import Login from './pages/Login';
import Register from './pages/Register';
import Futures from './pages/futures/Futures';
import FuturesTrade from './pages/futures/FuturesTrade';
import Subscriptions from './pages/subscriptions/Subscriptions';
import {BrowserRouter, Routes, Route} from "react-router-dom";

function App() {
  return (
    <div className="App">
        <BrowserRouter>
            <Routes>
             <Route path={'/'} Component={Futures} />
             <Route path={'/my/dashboard'} Component={Dashboard} />
             <Route path={'/my/api-managment'} Component={ApiKeys} />
             <Route path={'/my/api-clients-managment'} Component={ApiClientsKeys} />
             <Route path={'/my/subscriptions'} Component={Subscriptions} />
             <Route path={'/my/settings'} Component={Settings} />
             <Route path={'/login'} Component={Login} />
             <Route path={'/register'} Component={Register} />
             <Route path={'/futures'} Component={Futures} />
             <Route path={'/futures/:platform/:symbol'} Component={FuturesTrade} />
            </Routes>
        </BrowserRouter>
    </div>
  );
}

export default App;
