import React, {useState, useEffect } from 'react';
import axios from 'axios';
import {Position} from "../models/position";
import {OpenOrder} from "../models/openOrder";
import {Link} from 'react-router-dom';
import Modal from './Modal';
import CancelOpenOrder from "./CancelOpenOrder";
import alertMessage from "./Alert";
import { BsRepeat, BsArrowsFullscreen, BsCheck2Circle, BsCaretLeftFill, BsCaretRightFill } from "react-icons/bs";
import ClosePositionBy from "./ClosePositionBy";


interface ClientInfo {
    id: number;
    serviceName: string;
    client: string;
    client_id: number;
    platform: string;
    openOrdersTrader: OpenOrder[];
    openPositionsTrader: Position[];
    tradersBalance: number;
}

interface ReportMsg {
    symbol: string;
    action: string;
}


interface OpenPosition {
    orderId: string;
    orderLinkId: string;
    symbol: string;
    side: string;
    status: string;
    origQty: number;
    price: number;
    type: string;
    takeProfit: number;
    stopLoss: number;
    time: number;
    reduceOnly: boolean;
    tpslMode: string;
}

const InitOpenPositions:any = [];

interface SuccessProcesProps {
        positions: OpenPosition[];
        symbol: string;
    }

const InitClientStatus = {
    balance: 0,
    total_balance: 0,
    un_realised_pnl: 0,
    cum_realised_pnl: 0,
    positions: [],
}

const SubscriberStatus: React.FC<ClientInfo> = (props) => {
    const {id, serviceName, client, client_id, platform, openOrdersTrader, openPositionsTrader, tradersBalance} = props;
    const [error, setError] = useState('');
    const [clientStatus, setClientStatus] = useState(InitClientStatus);
    const [showModalAllOpenedOrders, setShowModalAllOpenedOrders] = useState(false);
    const [showModalReportMsg, setShowModalReportMsg] = useState(false);
    const [showOpenPositions, setShowOpenPositions] = useState(true);
    const [showOpenOrders, setShowOpenOrders] = useState(false);
    const [open_orders, setOpenOrders] = useState([]);
    const [synchron_proces, setSynchronProces] = useState(false);
    const [countPromise, setCountPromise] = useState(0);
    const [countPromiseFinish, setCountPromiseFinish] = useState(0);
    const [countPromiseOpOrFinish, setCountPromiseOpOrFinish] = useState(0);
    const [successSynchronPositions, setSuccessSynchronPositions] = useState(false);
    const [successSynchronOpenOrders, setSuccessSynchronOpenOrders] = useState(false);
    const [confirmTrue, setConfirmTrue] = useState(false);
    const [createdPositions, setCreatedPositions] = useState(InitOpenPositions);
    const [createdOpenOrders, setCreatedOpenOrders] = useState(InitOpenPositions);
    const [reportMsg, setReportMsg] = useState(InitOpenPositions);
    const [needToCreatePositions, setNeedToCreatePositions] = useState(InitOpenPositions);
//     const [inPosition, setInPosition] = useState(0);
    const allowable_percentage = 1
    const styleModal = {
        width: '60vw',
    }

    const trading_platform = platform.toLowerCase().split(' ')[0]
    const get_in_position = () => {
            let mount = 0
            if (clientStatus.positions) {
                    clientStatus.positions.map((position:Position) => {
                            mount += position.national
                        })
                }
            return mount.toFixed(2)
        }
    const getStatus = async (client_id: number, platform: string) => {
        setSynchronProces(true)
        if (client_id) {
            try {
                const response = await axios.get(`${trading_platform}/futures/account_info/BTCUSDT/${client_id}`);
//                 console.log(response)
                if (response.data.msg){
                    setError(response.data.msg)
//                     setClientStatus({balance: 'None', total_balance: 'None'})
                } else {
                    setClientStatus(response.data)
                    get_in_position()
                }
                const response_open_orders = await axios.get(`${trading_platform}/futures/all_open_orders/${client_id}`);
                if (response_open_orders.data.openOrders.msg !== undefined){
                    if (response_open_orders.data.openOrders.msg.includes("API-key format invalid")) {
                        setError('API-key format invalid')
                        setSynchronProces(false)
                    }
                }
                 else {
                    setOpenOrders(response_open_orders.data.openOrders);
                    setSynchronProces(false)
                }

            } catch (e:any) {
                setError(e.message)
                console.log(e.message)
                setSynchronProces(false)
          }

        }
    }

    const _open_positions= () => {
        setShowOpenPositions(true);
        setShowOpenOrders(false);
    }
    const _open_orders= () => {
        setShowOpenPositions(false);
        setShowOpenOrders(true);
    }
    const dateTime = (time: number) => {
        return new Intl.DateTimeFormat('en-US', {year: 'numeric', month: '2-digit',day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: false,}).format(time)
    }
    const margin = (position:Position) => {
        if (position.isolated) {
            return <span> {position.isolatedWallet}</span>
        }
        else {
            return <span> {position.initialMargin}</span>
        }
    }
    const handleCancelOrder = (orderId: string) => {
        const op_orders = open_orders.filter((o: OpenOrder) => o.orderId !== orderId)
        setOpenOrders(op_orders)
    }
    const action = (client_id: number, platform: string, symbol: string, orderId: string) => {
        return (
            <CancelOpenOrder
                orderId={orderId}
                endpoint={`${platform}/futures/order/${symbol}/${client_id}`}
                handleCancelOrder={handleCancelOrder}
            />
        )
    }
    const show_all_opened_orders = () => {
        return (
            <>
                <ul className="nav nav-tabs">
                    <li className="nav-item">
                        <Link className="nav-link" aria-current="page" to="#" onClick={_open_positions}>Positions ({clientStatus.positions ? clientStatus.positions.length : 0})</Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link" to="#" onClick={_open_orders}>Open Orders ({open_orders ? open_orders.length : 0})</Link>
                    </li>
                </ul>

                <div className="table-responsive small">
                        {showOpenPositions && (
                        <table className="table table-striped table-sm">
                        <thead>
                            <tr>
                              <th scope="col">Symbol</th>
                              <th scope="col">Size</th>
                              <th scope="col">Entry Price</th>
                              <th scope="col">Liq Price</th>
                              <th scope="col">Margin</th>
                              <th scope="col">PNL</th>
                              <th scope="col">TP</th>
                              <th scope="col">SL</th>
                              <th scope="col">Close By</th>
                            </tr>
                        </thead>
                        <tbody>
                            {clientStatus.positions && (clientStatus.positions.map(
                                    (position: Position) => {
                                        return (
                                            <tr key={position.symbol}>
                                                <td>
                                                    {position.symbol}&nbsp;
                                                    {position.leverage}x</td>
                                                <td><span className={(position.side==='Sell') ? 'red':'green'}>{position.positionAmt}  {position.symbol.replace('USDT','')}<br/>{position.national} USDT</span></td>
                                                <td>{position.entryPrice}</td>
                                                <td><span className='orange'>{(position.liqPrice) ? position.liqPrice: '--'}</span></td>
                                                <td>{margin(position)}</td>
                                                <td><span className={(position.unrealizedProfit<0) ? 'red':'green'}>{position.unrealizedProfit} USDT</span></td>
                                                <td>{position.takeProfit}</td>
                                                <td>{position.stopLoss}</td>
                                                <td>
                                                    <ClosePositionBy
                                                        platform={trading_platform}
                                                        position={position}
                                                        availableBalance={clientStatus.balance}
                                                        getStatus={getStatus}
                                                        setError={setError}
                                                        error={error}
                                                        client_id={client_id}
                                                    />
                                                </td>
                                            </tr>
                                        )
                                    }
                                ))}
                        </tbody>
                        </table>
                    )}
                    {showOpenOrders && (
                        <table className="table table-striped table-sm">
                        <thead>
                            <tr>
                              <th scope="col">Time</th>
                              <th scope="col">Symbol</th>
                              <th scope="col">Type</th>
                              <th scope="col">Side</th>
                              <th scope="col">Price</th>
                              <th scope="col">Amount</th>
                              <th scope="col">Reduce-Only</th>
                              <th scope="col">TP</th>
                              <th scope="col">SL</th>
                              <th scope="col">Cancel</th>
                            </tr>
                        </thead>
                        <tbody>
                            {(open_orders && platform) && (open_orders.sort((a,b) => b['time']-a['time']).map(
                                    (openOrder: OpenOrder) => {
                                        return (
                                            <tr key={openOrder.orderId}>
                                                <td>{dateTime(openOrder.time)}</td>
                                                <td>{openOrder.symbol}</td>
                                                <td>{openOrder.orderType}</td>
                                                <td>{openOrder.side}</td>
                                                <td>{openOrder.price}</td>
                                                <td>{(openOrder.origQty ? openOrder.origQty : openOrder.qty)}</td>
                                                <td>{openOrder.reduceOnly ? <span>True</span> : <span>No</span>}</td>
                                                <td>{openOrder.takeProfit}</td>
                                                <td>{openOrder.stopLoss}</td>
                                                <td className="pointer">
                                                    {action(client_id, trading_platform, openOrder.symbol, openOrder.orderId)}
                                                </td>
                                            </tr>
                                        )
                                    }
                                ))}
                        </tbody>
                        </table>
                    )}
                </div>
            </>
        )
    }
    const setLeverage = async (leverage: number, symbol: string) => {
        let client_leverage = false;

        try {
            const response = await axios.get(`${trading_platform}/futures/status_symbol/${symbol}/${client_id}/`);
            if (leverage && (Number(response.data.leverage) !== Number(leverage))){
                const response = await axios.post(`${trading_platform}/futures/set_leverage/${symbol}/`, {
                    leverage: leverage,
                    client_id: client_id,
                });
                if (response.data.trader.retMsg.includes('Error')) {
                    alertMessage('error', `${response.data.trader.retMsg}`);
                    setError(response.data.trader.retMsg)
                }
                if (response.data.trader.retMsg.includes('OK')) {
                    alertMessage('success', `${response.data.trader.retMsg}! Leverage has been updated!`);
                    client_leverage = true;
                }
                else {
                    alertMessage('warn', `${response.data.trader.retMsg}`)
                }
            } else {
                    if (leverage) {
                        client_leverage = true;
                    }
                }

        } catch (e:any) {
            alertMessage('error', `${e.message}`)
            setError(e.message)
            console.log('errorLeverage:', error)
            console.log('errorLeverage', e)
          }
        console.log('client_leverage', client_leverage)
        return client_leverage;
    }
    const waiting_proc = () => {
            return (
                <div className="spinner-border" role="status">
                    <span className="visually-hidden">Waiting...</span>
                </div>
            )
        }
    const getSynchronStatus = () => {
        let count = 0
        let is_positions = true
        let is_open_orders = true
        let new_positions: any = clientStatus.positions.slice()
//         console.log('positions client',new_positions)
//         console.log('positions trader',openPositionsTrader)
        const symbols_client_positions: any[] = []
        if (new_positions.length){
                new_positions.map((position: Position) => {symbols_client_positions.push(position.symbol)})
            }
        let symbols_client_positions_2 = symbols_client_positions.slice()

//         if (openPositionsTrader.length === clientStatus.positions.length) {
//             is_positions = true
//         }

//         if (count===0){setSynchronProces(true)}
        if (openPositionsTrader.length) {
            (openPositionsTrader.map(
                (position: Position) => {
                        if (!symbols_client_positions.includes(position.symbol)){
//                                 console.log('add new pos get synchrone ', position.symbol)
                                is_positions = false
                        }
                        else {
                                const position_client = new_positions.filter((pos: Position) => pos.symbol === position.symbol)[0]
                                const percent_pos_trader = (Number(position.initialMargin)/Number(tradersBalance)*100).toFixed(2)
                                const percent_pos_client = (Number(position_client.initialMargin)/Number(clientStatus.balance)*100).toFixed(2)

//                                 const percent_pos_trader = (Number(position.positionAmt)*Number(position.entryPrice)/Number(tradersBalance)*100).toFixed(2)
//                                 const percent_pos_client = (Number(position_client.positionAmt)*Number(position_client.entryPrice)/Number(clientStatus.balance)*100).toFixed(2)
                                const diff = (Number(percent_pos_trader)-Number(percent_pos_client)).toFixed(2)
                                if (Math.abs(Number(diff))>allowable_percentage){
                                        is_positions = false
//                                                 console.log('diff get synchrone',position.symbol, percent_pos_trader, percent_pos_client, (diff))
                                        if (Number(diff)<0){
//                                                 console.log('close pos client get synchrone', diff+'%', Number(position_client.positionAmt)*Number(position_client.entryPrice)*Math.abs(Number(diff))/Number(percent_pos_client))
                                            }
                                        else {
//                                                 console.log('add pos client get synchrone', diff+'%', Number(position_client.positionAmt)*Number(position_client.entryPrice)*Math.abs(Number(diff))*Number(percent_pos_client))
                                            }
                                }
                        }
                        symbols_client_positions_2 = symbols_client_positions_2.filter((symbol:string) => symbol !== position.symbol)
                        count += 1
//                         if (openPositionsTrader.length===count){
//                                 setSynchronProces(false)
//                             }
                }))

//                 console.log(openPositionsTrader.length, count)
                if (symbols_client_positions_2.length && openPositionsTrader.length===count) {
                    is_positions = false
//                         console.log('close all position get synchron',symbols_client_positions_2)
                    }

        } else {
                if (symbols_client_positions_2.length) {
                    is_positions = false
//                         console.log(openPositionsTrader.length, count)
//                         console.log('close all position get synchron',symbols_client_positions_2)
                    } else {
                        is_positions = true
                     }
            }
        if (openOrdersTrader.length != open_orders.length) {
                    is_open_orders = false
                }
        if (synchron_proces) {
            return (
                <div className="spinner-border" role="status">
                   <span className="visually-hidden">Waiting...</span>
                </div>
            )
        }
        if (is_positions && is_open_orders) {
            return (
                <>
                <span className='green'>Positions Ok <BsCheck2Circle/></span><br/>
                <span className='green'>Limits Ok <BsCheck2Circle/></span>
                </>
            )
        }
        if (!is_positions && !is_open_orders) {
            return (
            <>
                <span><a href='#synchron' className='red' onClick={() => {goSynchronPositions()}}><BsCaretRightFill/> Synhron Positions <BsCaretLeftFill/></a></span><br/>
                <span><a href='#synchron' className='red' onClick={() => goSynchronLimits()}><BsCaretRightFill/> Synhron Limits <BsCaretLeftFill/></a></span>
            </>
            )
        }
        if (!is_positions && is_open_orders) {
            return (
            <>
                <span ><a href='#synchron' className='red' onClick={() => {goSynchronPositions()}}><BsCaretRightFill/> Synhron Positions <BsCaretLeftFill/></a></span><br/>
                <span className='green'>Limits Ok <BsCheck2Circle/></span>
            </>
            )
        }
        if (is_positions && !is_open_orders) {
            return (
            <>
                <span className='green'>Positions Ok <BsCheck2Circle/></span><br/>
                <span><a href='#synchron' className='red' onClick={() => goSynchronLimits()}><BsCaretRightFill/> Synhron Limits <BsCaretLeftFill/></a></span>
            </>
            )
        }
    }

    const create_order_limit = async (openOrder: OpenOrder) => {
        const percent = (Number(openOrder.origQty)*Number(openOrder.price)/Number(tradersBalance)*100).toFixed(2)
        console.log('percent', Number(openOrder.origQty), Number(openOrder.price), Number(tradersBalance))
        let trader_lever = 0
        console.log('start create_order_limit', openOrder)
        const create_order_l = async () => {
            try {
                const response = await axios.post(`${trading_platform}/futures/order/${openOrder.symbol}/${client_id}`, {
                    order_type: openOrder.orderType,
                    side: openOrder.side,
                    price: openOrder.price,
                    size: openOrder.origQty,
                    take_profit: openOrder.takeProfit,
                    stop_loss: openOrder.stopLoss,
                    reduceOnly: openOrder.reduceOnly,
                    percent: percent,
                    orderId: openOrder.orderId,
                    tp_sl_mode: openOrder.tpslMode,
                });

                console.log('order: ',response.data);
                if (response.data.trader.orderId) {
                    alertMessage('success', `${response.data.trader.symbol}, ${response.data.trader.side},
                        ${response.data.trader.origQty} ${response.data.trader.symbol.replace('USDT', '')},
                        ${response.data.trader.price} USDT`
                    );
                    if (response.data.trader.status === "NEW"){
                        if (response.data.trader.type==="LIMIT") {
                            return response.data.trader
                        }
                    }
                }
                else {
                    alertMessage('error', `${response.data.trader.msg}`)
                }

            } catch (e: any) {
                alertMessage('error', `${e.message}`)
                setError(e.message)
            }
        }

        try {
            const trader_leverage_symbol = await axios.get(`${trading_platform}/futures/status_symbol/${openOrder.symbol}/`);

            trader_lever = trader_leverage_symbol.data.leverage
        } catch (e: any) {
                alertMessage('error', `${e.message}`)
                setError(e.message)
            }

        const promise_leverage = new Promise(function(resolve, reject) {
                            resolve(setLeverage(trader_lever, openOrder.symbol))
                        })

        promise_leverage.then(function(client_leverage_true:any){
            if (client_leverage_true!==undefined){
                //client_leverage_true = false //dellll!!
                if (client_leverage_true){
                    const promice_create_order = new Promise(function(resolve, reject) {
                            resolve(create_order_l())
                        })
                    promice_create_order.then(function(createdNewOrder:any){
                            if (createdNewOrder!==undefined ){
                                    setCreatedOpenOrders((prev:any)=>[...prev, createdNewOrder])
                                }
                        })

                } else {
                        setError('The leverage of clients has not changed! Try again!')
                        setCreatedOpenOrders((prev:any)=>[...prev, {symbol:openOrder.symbol, type: 'The leverage of clients has not changed! Try again!'}])
                        alertMessage('error', `The leverage of clients has not changed! Try again!`)
                        return {msg: "The leverage of clients has not changed! Try again!"}
                    }
            }
        })
    }

    const create_order_market = async (position: Position, size_in_USDT?: boolean, close_by_client?: boolean) => {
        const percent = (Number(position.positionAmt)*Number(position.entryPrice)/Number(tradersBalance)*100).toFixed(2)
        let new_take_profit = 0

        let _size_in_USDT = false
        let _close_by_client = false
        if (close_by_client) {
            _close_by_client = true
        }

        if (size_in_USDT) {
            _size_in_USDT = size_in_USDT
            _close_by_client = true
        }
        if (position.takeProfit){
            new_take_profit = position.takeProfit
        }
        let new_stop_loss = 0
        if (position.stopLoss){
            new_stop_loss = position.stopLoss
        }
        const create_order = async () => {
                try {
                        const response = await axios.post(`${trading_platform}/futures/order/${position.symbol}/${client_id}`, {
                            order_type: "MARKET",
                            side: position.side,
                            size: position.positionAmt,
                            take_profit: new_take_profit,
                            stop_loss: new_stop_loss,
                            percent: percent,
                            orderId: null,
                            close_by_client: _close_by_client,
                            size_in_USDT: _size_in_USDT,
                            tp_sl_mode: position.tpslMode,
                        });

                        console.log('position: ',response.data);
                        if (response.data.trader.orderId) {
                            alertMessage('success', `${response.data.trader.type}, ${response.data.trader.symbol}, ${response.data.trader.side},
                                ${response.data.trader.origQty} ${response.data.trader.symbol.replace('USDT', '')},
                                ${response.data.trader.price} USDT, ${((Number(response.data.trader.origQty)*Number(response.data.trader.price)).toFixed(2))} USDT`
                            );
                            return response.data.trader
                        }
                        else {
                            setError(response.data.trader.msg)
                            alertMessage('error', `${response.data.trader.msg}`)
                            return response.data.trader.msg
                        }

                } catch (e: any) {
                    alertMessage('error', `${e.message}`)
                    setError(e.message)
                    return e.message
                }
        }

        const promise_leverage = new Promise(function(resolve, reject) {
                            resolve(setLeverage(position.leverage, position.symbol))
                        })

        promise_leverage.then(function(client_leverage_true:any){
            if (client_leverage_true!==undefined){
                client_leverage_true = false //dellll!!
                if (client_leverage_true){
                    const promice_create_order = new Promise(function(resolve, reject) {
                            resolve(create_order())
                        })
                    promice_create_order.then(function(createdNewOrder:any){
                            if (createdNewOrder!==undefined ){
                                    setCreatedPositions((prev:any)=>[...prev, createdNewOrder])
                                }
                        })

                } else {
                        setError('The leverage of clients has not changed! Try again!')
                        setCreatedPositions((prev:any)=>[...prev, {symbol:position.symbol, type: 'The leverage of clients has not changed! Try again!'}])
                        alertMessage('error', `The leverage of clients has not changed! Try again!`)
                        return {msg: "The leverage of clients has not changed! Try again!"}
                    }
            }
        })
    }

    const goSynchronLimits = async () => {
        setSynchronProces(true)
        setSuccessSynchronOpenOrders(true)
        //setReportMsg((prev:any)=> prev=[])
        setCreatedOpenOrders((prev:any)=> prev=[])
        setError('')
        let new_open_orders: any = open_orders.slice()

        const symbols_client_open_orders: any[] = []
        console.log('start goSynchronLimits')
        open_orders.map((openOrder: OpenOrder) => {symbols_client_open_orders.push(`${openOrder.symbol},${openOrder.side},${Number(openOrder.price)}`)})
        console.log('openOrdersTrader.length, open_orders.length', openOrdersTrader.length, open_orders.length)
        if (openOrdersTrader.length){
            if (openOrdersTrader.length !== open_orders.length) {
                {(openOrdersTrader) && (openOrdersTrader.sort((a,b) => b['time']-a['time']).map(
                    (openOrder: OpenOrder) => {
                        if (!symbols_client_open_orders.includes(`${openOrder.symbol},${openOrder.side},${Number(openOrder.price)}`)){
                            const promise = new Promise(function(resolve, reject) {
                                console.log('create_order_limit', openOrder)
                                resolve(create_order_limit(openOrder))
                            })
                            promise.then(function(v:any){
                                setCountPromiseOpOrFinish((prev) => prev+1)
                                if (v!==undefined) {
//                                     new_open_orders.push(v)
//                                     console.log('limit new_open_orders',new_open_orders)
//                                     if (new_open_orders.length >= openOrdersTrader.length){
//                                         if (new_open_orders.includes(undefined)){
//                                                 alertMessage('warn', 'Synchronization of limit orders was not achieved!')
//                                                 setOpenOrders(new_open_orders)
//                                                 getStatus(client_id, platform)
//                                                 setSynchronProces(false)
//                                             } else {
//                                                     alertMessage('success', 'Synchronisation limits done!')
//                                                     setOpenOrders(new_open_orders)
//                                                     getStatus(client_id, platform)
//                                                     setSynchronProces(false)
//                                                 }
//                                     }
                                }
                            })
                        }
                    }
                ))}
            }
        } else {
            alertMessage('warn', 'Trader has no open limits!')
            setSynchronProces(false)
        }
    }

    if (successSynchronPositions && (countPromise === countPromiseFinish)){
        if (countPromiseFinish === createdPositions.length){
            getStatus(client_id, platform)
            setSuccessSynchronPositions(false)
            setConfirmTrue(false)
            setSynchronProces(false)
        }
    }

    if (successSynchronOpenOrders && countPromiseOpOrFinish>0) {
        if (countPromiseOpOrFinish === createdOpenOrders.length){
            getStatus(client_id, platform)
            setSuccessSynchronOpenOrders(false)
            setSynchronProces(false)
        }
    }
    const goSynchronPositions = async (confirm_true?: boolean) => {

        setNeedToCreatePositions((prev:any)=> prev=[])
        setReportMsg((prev:any)=> prev=[])
        setCreatedPositions((prev:any)=> prev=[])
        setError('')
        setSynchronProces(true)
        setSuccessSynchronPositions(false)
        setCountPromise(0)
        setCountPromiseFinish(0)
        let count = 0
        const symbols_client_positions: any[] = []
        let positions_client: any = clientStatus.positions.slice()
        let report_msg: any = []
        const size_in_USDT = true

        if (positions_client.length){
                positions_client.map((position: Position) => {symbols_client_positions.push(position.symbol)})
            }
        let symbols_client_positions_2 = symbols_client_positions.slice()

        if (openPositionsTrader.length) {
            openPositionsTrader.map(
                (position: Position) => {
                    count ++
                    if (!symbols_client_positions.includes(position.symbol)){
                        setNeedToCreatePositions((prev:any)=>[...prev, {action:'Create order market', position}])
                        setReportMsg((prev:any)=>[...prev, {symbol: position.symbol, action: 'Create order market'}])

                        if (confirm_true) {
                            const promise = new Promise(function(resolve, reject) {
                                resolve(create_order_market(position))
                                setCountPromise((prev) => prev+1)
                            })

                            console.log(count, openPositionsTrader.length)
                            promise.then(function(v:any){
                                setCountPromiseFinish((prev) => prev+1)
                            })
                        }
                    } else {
                            // If there is position, we check it for compliance
                            const position_client = positions_client.filter((pos: Position) => pos.symbol === position.symbol)[0]
                            const percent_pos_trader = (Number(position.initialMargin)/Number(tradersBalance)*100).toFixed(2)
                            const percent_pos_client = (Number(position_client.initialMargin)/Number(clientStatus.balance)*100).toFixed(2)
//                             const percent_pos_trader = (Number(position.positionAmt)*Number(position.entryPrice)/Number(tradersBalance)*100).toFixed(2)
//                             const percent_pos_client = (Number(position_client.positionAmt)*Number(position_client.entryPrice)/Number(clientStatus.balance)*100).toFixed(2)
                            const diff = (Number(percent_pos_trader)-Number(percent_pos_client)).toFixed(2)
                            if (Math.abs(Number(diff))>allowable_percentage){
//                                             console.log('diff', position.symbol, percent_pos_trader, percent_pos_client, (diff+'%'))
                                    if (Number(diff)<0){
                                            // new_size - size in USDT
                                            const new_size = Number(position_client.positionAmt)*Number(position_client.entryPrice)*Math.abs(Number(diff))/Number(percent_pos_client)
                                            let new_side = 'Sell'
//                                             console.log('close pos client', diff+'%', new_size)

                                            if (position.side === 'Sell') {
                                                    new_side = 'Buy'
                                                }

                                            const close_part_pos = {
                                                symbol: position.symbol,
                                                side: new_side,
                                                positionAmt: new_size,
                                                entryPrice:position.entryPrice,
                                                takeProfit: 0,
                                                stopLoss: 0,
                                                tp_sl_mode: position.tpslMode,
                                                initialMargin: position.initialMargin, unrealizedProfit: position.unrealizedProfit,
                                                leverage: position.leverage, isolated: position.isolated, national: position.national,
                                                isolatedWallet: position.isolatedWallet, liqPrice: position.liqPrice, margin: position.margin,
                                                }
                                            setNeedToCreatePositions((prev:any)=>[...prev, {action:'Close part pos', close_part_pos}])
                                            setReportMsg((prev:any)=>[...prev, {symbol: position.symbol, action: `Close part of the position -${new_size.toFixed(2)} USDT (${diff}%)`}])

                                            if (confirm_true){
                                                const promise_close_part_pos = new Promise(function(resolve, reject) {
                                                        resolve(create_order_market(close_part_pos, size_in_USDT))
                                                        setCountPromise((prev) => prev+1)
                                                    })
                                                    promise_close_part_pos.then(function(v:any){
                                                        setCountPromiseFinish((prev) => prev+1)

                                                    })
                                            }
                                    } else {
                                            // new_size - size in USDT
                                            const new_size = Number(position_client.positionAmt)*Number(position_client.entryPrice)*Math.abs(Number(diff))/Number(percent_pos_client)
                                            let new_side = 'Buy'
//                                             console.log('add pos client ', diff+'%', new_size)
                                            if (position.side === 'Sell') {
                                                    new_side = 'Sell'
                                                }

                                            const add_part_pos = {
                                                symbol: position.symbol,
                                                side: new_side,
                                                positionAmt: new_size,
                                                entryPrice:position.entryPrice,
                                                takeProfit: 0,
                                                stopLoss: 0,
                                                tp_sl_mode: position.tpslMode,
                                                initialMargin: position.initialMargin, unrealizedProfit: position.unrealizedProfit,
                                                leverage: position.leverage, isolated: position.isolated, national: position.national,
                                                isolatedWallet: position.isolatedWallet, liqPrice: position.liqPrice, margin: position.margin,
                                                }
                                            setNeedToCreatePositions((prev:any)=>[...prev, {action:'Add part pos', add_part_pos}])
                                            setReportMsg((prev:any)=>[...prev, {symbol: position.symbol, action: `Increase position +${new_size.toFixed(2)} USDT +(${diff}%)`}]);

                                            if (confirm_true){
                                                const promise_add_part_pos = new Promise(function(resolve, reject) {
                                                        resolve(create_order_market(add_part_pos, size_in_USDT))
                                                        setCountPromise((prev) => prev+1)
                                                    })
                                                    promise_add_part_pos.then(function(v:any){
                                                        setCountPromiseFinish((prev) => prev+1)

                                                    })
                                            }
                                    }
                            }
                    }

                    symbols_client_positions_2 = symbols_client_positions_2.filter((symbol:string) => symbol !== position.symbol)
                    if (count === openPositionsTrader.length) {
                            if (symbols_client_positions_2.length) {

                                symbols_client_positions_2.map((symbol:string)=>{

                                        const close_by_client = true
                                        let position_client = positions_client.find((pos: Position) => pos.symbol === symbol)
                                        if (position_client){
                                                if (position_client.side === "Buy"){
                                                        position_client.side = "Sell"
                                                    } else {
                                                        position_client.side = "Buy"
                                                    }
                                                setNeedToCreatePositions((prev:any)=>[...prev, {action:'Delete pos', position}])
                                                setReportMsg((prev:any)=>[...prev, {symbol: symbol, action: 'Close position!'}])
                                                if (confirm_true){
                                                    const promise_close_pos = new Promise(function(resolve, reject) {
                                                            resolve(create_order_market(position_client, false, close_by_client))
                                                            setCountPromise((prev) => prev+1)
                                                        })
                                                        promise_close_pos.then(function(v:any){
                                                            setCountPromiseFinish((prev) => prev+1)
                                                        })
                                                }
                                        }
                                })

                            }
                            if (confirm_true){
                                setSuccessSynchronPositions(true)
                            }
                            setShowModalReportMsg(true)

                    }
                }
            )
        }

    }

    const SuccessProces: React.FC<SuccessProcesProps> = (props) => {
        const {symbol, positions} = props;
        const resFind = positions.find((pos:OpenPosition) => pos.symbol === symbol)
            if (resFind!==undefined) {
                return  (
                    <>
                        <div className="col">
                            <span className={resFind.orderId ? 'green': 'red'}> {resFind.orderId ? <BsCheck2Circle/>: 'Error!'}</span>
                        </div>
                        <div className="col">
                            {resFind.type} {resFind.side} {resFind.origQty} {resFind.symbol.replace('USDT','')} {(Number(resFind.origQty)*Number(resFind.price)).toFixed(2)} USDT ({resFind.price} USDT)
                        </div>
                    </>
                    )
            } else {
                const waiting = () => {
                    if (confirmTrue){
                        return (
                            <div className="spinner-border" role="status">
                                <span className="visually-hidden">Waiting...</span>
                            </div>
                            )
                    } else {return}
                }
                return (
                    <>
                    <div className="col">
                        {error ? <span className="red">Error!</span> : waiting()}
                    </div>
                    <div className="col">
                        {error ? <span className="red">{error}</span> : ''}
                    </div>
                    </>
                    )
                }
    }

    const show_report_msg = () => {
        const buttons = () => {
                if (synchron_proces) {
                     return (
                         <>
                            <button className="button_long" onClick={()=>{setConfirmTrue(true); goSynchronPositions(true)}}>Confirm</button>
                            <button className="button_grey" onClick={() => {setShowModalReportMsg(false); setSynchronProces(false)}}>Cancel</button>
                         </>
                         )
                    } else {
                         return (
                             <>
                                <button className="button_grey" onClick={() => {setShowModalReportMsg(false); setSynchronProces(false)}}>OK</button>
                             </>
                             )
                        }

            }
           return (
                 <div className="container report_table">
                    <h5>Report ({client})</h5>
                    <hr/>
                    Available balance: Subscriber {clientStatus.balance} USDT | Trader {tradersBalance} USDT
                    <div className="row">
                        <div className="col"><b>Coins</b></div>
                        <div className="col"><b>Actions</b></div>
                        <div className="col"><b>Status</b></div>
                        <div className="col"><b>Notations</b></div>
                    </div>
                            {reportMsg.length && (reportMsg.map(
                                    (msg:ReportMsg) => {
                                            return (
                                                <div className="row">
                                                    <div className="col">
                                                        {msg.symbol}
                                                    </div>
                                                    <div className="col">
                                                        {msg.action}
                                                    </div>
                                                    <SuccessProces symbol={msg.symbol} positions={createdPositions}/>
                                                </div>
                                                )
                                        })
                                    )}
                    <center>
                        {buttons()}
                    </center>
                 </div>
           )
    }

    useEffect(()=> {
        if (client_id) {
            getStatus(client_id, platform);
        }
    },[client_id]);

    if (error.includes("403")) {
        return (
            <div className="form_row">
                 <a href="/login">Login</a> or <a href="/register">Register Now</a> to trade
            </div>
        )
    }
    const click_outside = (click_true: boolean) => {
            setShowModalReportMsg(click_true);
            setSynchronProces(click_true);
        }
    return (
        <>
            <tr key={id}>
                <td>{client}</td>
                <td>{serviceName} </td>
                <td>{clientStatus.total_balance} {(clientStatus.total_balance) ? "USDT" : null }</td>
                <td>{clientStatus.balance} {(typeof clientStatus.balance === "number") ? "USDT" : null } <a href="#upd" onClick={()=>getStatus(client_id, platform)}> <BsRepeat/> </a></td>
                <td>{(clientStatus.total_balance) ? <a href="#open_orders" onClick={() => setShowModalAllOpenedOrders(true)}>{(get_in_position())} USDT <BsArrowsFullscreen/></a> : 0}
                    <br/>Positions: {(clientStatus.positions) ? clientStatus.positions.length : 0}
                    <br/>Opened Orders: {(open_orders) ? open_orders.length : 0}</td>
                <td><span className={(clientStatus.un_realised_pnl>=0) ? "green" : "red" }>{clientStatus.un_realised_pnl} USDT</span></td>
                <td><span className={(clientStatus.cum_realised_pnl>=0) ? "green" : "red" }>{clientStatus.cum_realised_pnl} USDT</span></td>
                <td>{getSynchronStatus()}</td>
            </tr>
            {showModalAllOpenedOrders && (
                <Modal
                    active={showModalAllOpenedOrders}
                    setActive={setShowModalAllOpenedOrders}
                    style={styleModal}
                >
                    {show_all_opened_orders()}
                </Modal>
            )}
            {showModalReportMsg && (
                <Modal
                    active={showModalReportMsg}
                    setActive={click_outside}
                    style={styleModal}
                >
                    {show_report_msg()}
                </Modal>
            )}


        </>
    )
}

export default SubscriberStatus;