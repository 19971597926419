import React, {Component, SyntheticEvent} from 'react';
import axios from 'axios';
import {Navigate} from 'react-router-dom';

class Register extends Component {
    first_name = '';
    last_name = '';
    email = '';
    password = '';
    password_confirm = '';
    state = {
        redirect: false
    }

    submit = async (e: SyntheticEvent) => {
        e.preventDefault();

        await axios.post('register', {
            first_name: this.first_name,
            email: this.email,
            password: this.password,
            password_confirm: this.password_confirm,
        });

        this.setState({
            redirect: true
        })
    }

    render() {
        if (this.state.redirect) {
            return <Navigate to={'/login'} replace={true}/>;
        }

        return (
            <form className="form-signin w-100 m-auto" onSubmit={this.submit}>
                <h1 className="h3 mb-3 fw-normal">Please register</h1>
                <div className="form-floating">
                    <input type="text" id="firstName" className="form-control" placeholder="First Name" required
                           onChange={e => this.first_name = e.target.value}
                    />
                    <label htmlFor="firstName">First Name</label>
                </div>
                <div className="form-floating">
                    <input type="email" id="inputEmail" className="form-control" placeholder="Email address" required
                           onChange={e => this.email = e.target.value}
                    />
                    <label htmlFor="inputEmail">Email address</label>
                </div>
                <div className="form-floating">
                    <input type="password" id="inputPassword" className="form-control" placeholder="Password"
                           onChange={e => this.password = e.target.value}
                           required/>
                    <label htmlFor="inputPassword">Password</label>
                </div>
                <div className="form-floating">
                    <input type="password" id="passwordConfirm" className="form-control" placeholder="Password Confirm"
                           onChange={e => this.password_confirm = e.target.value}
                           required/>
                    <label htmlFor="passwordConfirm">Password Confirm</label>
                </div>
                <button className="btn btn-primary w-100 py-2" type="submit">Register</button>
            </form>
        );
    }
}

export default Register;