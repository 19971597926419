import React, {Component} from 'react';
import axios from "axios";
import { BsTrash } from "react-icons/bs";
import alertMessage from "./Alert";
import {OpenOrder} from "../models/openOrder";

type CancelOrders = {
    success: string,
    orders: [],
    client_email: string,
    retMsg: string,
}

const alertNotifY = (type: string, msg: string) => {
    return alertMessage(type, msg)
}

class CancelOpenOrdersAll extends Component<{open_orders: OpenOrder[], endpoint: string, handleCancelOrdersAll: any}> {
    cancel = async () => {
        if (window.confirm('Are you sure you want to cancel open orders all?')) {
            const response = await axios.delete(`${this.props.endpoint}/`);
            console.log('CancelOpenOrdersAll response: ', response.data)
            if (response.data.trader.success === '1'){
                this.props.handleCancelOrdersAll();
                alertNotifY('success', 'Orders trader was successfully canceled!')
            } else {
                 alertNotifY('error', 'Orders trader were not canceled!')
            }
            if (response.data.subscribers.result.length){
                const count_canceled_orders_subscribers = response.data.subscribers.result.length
                let i = 0
                response.data.subscribers.result.map(
                    (client: CancelOrders) => {
                        if ((client.orders.length === response.data.trader.orders.length) || (client.retMsg === "OK")){
                            i = i + 1
                            alertNotifY('success', 'Subscriber orders have been canceled!')
                        }
                        else {
                            alertNotifY('error',`Subscriber ${client.client_email} orders were not canceled!`)
                        }
                    }
                )
                if (i === count_canceled_orders_subscribers){
                    alertNotifY('success', `Subscriber orders ${count_canceled_orders_subscribers} have been canceled successfully!`)
                }
                else {
                    alertNotifY('warn', `Subscriber orders ${count_canceled_orders_subscribers-i} were not canceled!`)
                }
            }
        }
    }

    render() {
        return (
        <>
            {this.props.open_orders.length ? <a href="#cancel_all" onClick={() => this.cancel()}>Cancel All</a> : <a href="#cancel_all" className="by-button--disabled" >Cancel All</a>}
        </>
        );
    }
}

export default CancelOpenOrdersAll;