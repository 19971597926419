import React, {Component, SyntheticEvent} from 'react';
import axios from 'axios';
import {Navigate, Link} from 'react-router-dom';
import Nav from "../../components/Nav";
import Symbols from "../../components/Symbols";

class Futures extends Component {
    email = '';
    password = '';
    state = {
        redirect: true,
        error: ''
    }

    submit = async (e: SyntheticEvent) => {
        e.preventDefault();
        try {
            const response = await axios.post('login', {
                email: this.email,
                password: this.password
            });

            localStorage.setItem('token', response.data.jwt);
            axios.defaults.headers.Authorization = `Bearer ${localStorage.getItem('token')}`;

            this.setState({
                redirect: true
            })
         } catch (e) {
                console.log("Error:", {e})
                this.setState({
                    error: 'Invalid login or password, please try again!'
                })
            }
    }
    render() {
        if (this.state.redirect){
//             return <Navigate to='/futures/BTCUSDT'/>
        }
        return (
            <>
            <Nav/>
                <div className="container-fluid">
                  <div className="row">
                     <div className="sidebar border border-right col-md-3 col-lg-2 p-0 bg-body-tertiary">
                        <div className="offcanvas-md offcanvas-end bg-body-tertiary" id="sidebarMenu" aria-labelledby="sidebarMenuLabel">

                        </div>
                     </div>
                     <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
                        <ul className="nav nav-tabs">
                            <li className="nav-item">
                                <Link className="nav-link" to="/futures/binance/BTCUSDT/" >Binance</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to="/futures/bybit/BTCUSDT/" >Bybit</Link>
                            </li>
                        </ul>
                     </main>
                  </div>
                </div>
            </>

        )
    }
}
export default Futures