// import {Role} from "./role";

export class User {
    id: number;
    first_name: string;
    email: string;
    role: number;
    permissions: string[];

    constructor(id = 0, first_name = '', email = '', role = 0, permissions: string[] = []) {
        this.id = id;
        this.first_name = first_name;
        this.email = email;
        this.role = role;
        this.permissions = permissions;
    }

    get name() {
        return this.first_name;
    }

    canView(page: string) {
        return this.permissions.some(p => p === `view_${page}`);
    }

    canEdit(page: string) {
        return this.permissions.some(p => p === `edit_${page}`);
    }
}