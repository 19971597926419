import React, {Component, SyntheticEvent} from 'react';
import axios from 'axios';
import {Navigate, Link} from 'react-router-dom';

class Login extends Component {
    email = '';
    password = '';
    state = {
        redirect: false,
        error: ''
    }

    submit = async (e: SyntheticEvent) => {
        e.preventDefault();
        try {
            const response = await axios.post('login', {
                email: this.email,
                // зашифрувати пароль!!!
                password: this.password
            });
//             alert('token: '+response.data.jwt);
            localStorage.setItem('token', response.data.jwt);
            axios.defaults.headers.Authorization = `Bearer ${localStorage.getItem('token')}`;

            this.setState({
                redirect: true
            })
         } catch (e) {
                console.log("Error:", {e})
                this.setState({
                    error: 'Invalid login or password, please try again!'
                })
            }
    }
    render() {
        if (this.state.redirect){
            return <Navigate to='/my/dashboard'/>
        }
        return (
            <>
                <main className="form-signin w-100 m-auto">
                  <form onSubmit={this.submit}>
                    <h1 className="h3 mb-3 fw-normal">Please sign in</h1>

                    <div className="form-floating">
                      <input type="email" className="form-control" id="inputEmail" placeholder="name@example.com"
                      required onChange = {e => this.email = e.target.value} />
                      <label htmlFor="inputEmail">Email address</label>
                    </div>
                    <div className="form-floating">
                      <input type="password" className="form-control" id="inputPassword" placeholder="Password"
                      required onChange = {e => this.password = e.target.value}/>
                      <label htmlFor="inputPassword">Password</label>
                    </div>
                    <div className="form-error">
                    {this.state.error}
                    </div>
                    <button className="btn btn-primary w-100 py-2" type="submit">Sign in</button>
                  </form>
                <p></p>
                <p>Not on Copytrading yet?
                <Link to={'/register'} className="p-2">Register</Link>
                </p>
                </main>
            </>
        )
    }
}
export default Login