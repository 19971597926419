import React from 'react';
import {Link} from 'react-router-dom';

const Menu = (props: any) => {
    const {role} = props;
    let addModeratorMenu = null;
    if (role) {
        addModeratorMenu = (
            <li className="nav-item">
                <Link className="nav-link d-flex align-items-center gap-2" to="/my/api-clients-managment">
                    <svg className="bi"><use href="#"/></svg>
                    API clients managment
                </Link>
                <Link className="nav-link d-flex align-items-center gap-2" to="/my/subscriptions">
                    <svg className="bi"><use href="#"/></svg>
                    Subscriptions (copytrading)
                </Link>
            </li>
        )
    }
    return (

    <div className="offcanvas-body d-md-flex flex-column p-0 pt-lg-3 overflow-y-auto">

      <ul className="nav flex-column">
        <li className="nav-item">
          <Link className="nav-link d-flex align-items-center gap-2 active" aria-current="page" to="/my/dashboard">
            <svg className="bi"><use href="#"/></svg>
            Dashboard
          </Link>
        </li>
        <li className="nav-item">
          <Link className="nav-link d-flex align-items-center gap-2" to="/my/api-managment">
            <svg className="bi"><use href="#"/></svg>
            API managment
          </Link>
        </li>
        {addModeratorMenu}
        <li className="nav-item">
          <Link className="nav-link d-flex align-items-center gap-2" to="/my/settings">
            <svg className="bi"><use href="#"/></svg>
            Settings
          </Link>
        </li>

      </ul>

    </div>
    )
}

export default Menu;