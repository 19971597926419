import {User} from "../../models/user";
import {SET_USER} from "../../constants/constants";

const setUser = (user: User) => {
    return {
        type: SET_USER,
        user: user
    }
}

export default setUser;