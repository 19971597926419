import React, { useEffect, useState, ChangeEvent } from 'react';
import axios from 'axios';
import {Navigate, useParams, Link} from 'react-router-dom';
import Nav from "../../components/Nav";
import Symbols from "../../components/Symbols";
import TradeForm from "../../components/TradeForm";
import Positions from '../../components/Positions';
import DataSymbol from '../../components/DataSymbol';
import {Position} from "../../models/position";
import { BsSearch } from "react-icons/bs";
import Modal from '../../components/Modal';
import { useDispatch, useSelector } from "react-redux";
import { setOpenOrder, setOpenOrders } from "../../redux/actions/setOpenOrdersAction";
import { setPositions } from "../../redux/actions/PositionsAction";
import { OpenOrder } from "../../models/openOrder";
import { User } from "../../models/user";
import WrapperTrading from '../../components/WrapperTrading';

const initState = {
        currentSymbol: {
            symbol:'',
            marginType: '',
            leverage: '',
            price: 0,
            markPrice: 0,
        },
        availableBalance: 0,
        totalBalance: 0,
        positions: [],
        symbols: [],
}

interface AccInfoState {
    currentSymbol: {
        symbol:string;
        marginType: string;
        leverage: string;
        price: number;
        markPrice: number;
    };
    availableBalance: number;
    totalBalance: number;
    positions: Position[];
    symbols: ISymbol[]|any;
}

interface ISymbol {
    id: number;
    symbol: any;
}

interface UserType {
    user: User;
}

interface UserTypeRedux {
    user: UserType;
}

const FuturesTrade = () => {
    const {platform, symbol} = useParams();
    const [accInfo, setAccInfo] = useState<AccInfoState>(initState);
    const [error, setError] = useState('');
    const [symbolsSearch, setSymbolSearch] = useState([]);
    const [symbolsAccInfoView, setSymbolAccInfoView] = useState(true);
    const [modalActive, setModalActive] = useState(false);
    const dispatch = useDispatch();

    const setNewOpenOrder = (open_orders: OpenOrder[]) => {
        dispatch(setOpenOrder(open_orders));
    }
    const updatePosition = () => {
        if (platform && symbol){
            getaccInfo(platform, symbol);
        }
    }
    const updateAccInfo = async (platform: string, symbol: string) => {
        await getOpenOrders(platform);
        await getaccInfo(platform, symbol)
    }

    const user: User = useSelector((state: UserTypeRedux) => state.user.user)

    const getOpenOrders = async (platform: string) => {

        try {
            const response = await axios.get(`${platform}/futures/all_open_orders/`);
            if (response.data.openOrders.msg !== undefined){
                if (response.data.openOrders.msg.includes("API-key format invalid")) {
                    setError('API-key format invalid')
                }
            }
             else {
                dispatch(setOpenOrders(response.data.openOrders));
            }
        } catch (e:any) {
                console.log("Error:", {e})
                if (e.message.includes("403")){
                    setError('403 Forbidden');

                } else {
                    setError(e.message);
                }
         }
    }
    const getaccInfo = async (platform: string, symbol: string) => {
        if (symbol && platform) {
            try {
                const response = await axios.get(`${platform}/futures/account_info/${symbol}/`);
//                 console.log(response)
                if (response.data.code){
                    setError(response.data.msg)
                } else {
                    setAccInfo(response.data)
                    dispatch(setPositions(response.data.positions));
                }

            } catch (e:any) {
                setError(e.message)
                console.log(e.message)
          }

        }
    }

    useEffect(()=> {
        if (platform && symbol) {
            getaccInfo(platform, symbol);
            setSymbolSearch(accInfo.symbols);
            getOpenOrders(platform);
        }
    },[symbol]);

    const handleClick = () => {
        setSymbolAccInfoView(false);
//         setSymbolSearch([]);
    }

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        const {name, value} = e.target;
        let symbols_s: any = accInfo.symbols.filter((s: ISymbol) => s.symbol.startsWith(value.toUpperCase()))

        setSymbolSearch(symbols_s)
    }

    if (error.includes("403")) {
        return <Navigate to='/login'/>
    }

    if (error.includes("API-key format invalid")) {
        return <Navigate to='/my/api-managment'/>

    }

    if (accInfo.currentSymbol && platform) {
        return (
            <WrapperTrading>
            <Nav/>
            <div className="container-fluid">
                <div className="roww">
                    <div className="sidebar border border-right col-md-3 col-lg-2 p-2 pt-lg-3 bg-body-tertiary " >
                        <div className="offcanvas-md offcanvas-end bg-body-tertiary overflow-y-auto" id="sidebarMenu" aria-labelledby="sidebarMenuLabel" style={{ height: '400px', margin: '0 10px' }}>
                            <div className="input-group">
                                <span className="input-group-text" style={{height:'31px', margin:'5px 0px 0px 0px'}}>
                                <BsSearch /></span>
                                <input
                                    name='symbol_search'
                                    type="search"
                                    className="form-control form-control-sm"
                                    onChange={handleChange}
                                    onClick={handleClick}
                                />
                            </div>
                            {symbolsAccInfoView && (<Symbols platform={platform} symbols={accInfo.symbols}/>)}
                            {!symbolsAccInfoView&& (<Symbols platform={platform} symbols={symbolsSearch}/>)}
                        </div>
                    </div>

                    <main className="col-md-9 ms-sm-auto col-lg-8 px-md-4">
                        <div className="container-fluid">
                            <hr/>
                            <b>{platform[0].toUpperCase()+platform.slice(1)} </b> <span className='red'>{error}</span> <br/>
                                <span className={user.role === 1 ? 'green' : 'red'}>{user.role === 1 ? 'TRADER' : 'SUBSCRIBER'} </span>
                                <Link to="/my/dashboard">Dashboard</Link>
                            <hr/>
                        </div>
                        <DataSymbol
                            platform={platform}
                            symbol={symbol}
                            price={accInfo.currentSymbol.price}
                            markPrice={accInfo.currentSymbol.markPrice}
                        />

                        <div className="clearfix">
                        </div>

                        <Positions
                            platform={platform}
                            availableBalance = {accInfo.availableBalance}
                            setNewOpenOrder={setNewOpenOrder}
                            updatePosition={updatePosition}
                        />

                        {(platform && symbol) && (
                            <button type="button" className="btn btn-secondary btn-sm" onClick={() => updateAccInfo(platform, symbol)}>Update</button>
                        )}


                    </main>

                    <div className="sidebar border border-left col-lg-2 ">
                        <div className="offcanvas-md offcanvas-end bg-body-tertiary" id="sidebarMenu" aria-labelledby="sidebarMenuLabel">
                            <TradeForm
                                marginType = {accInfo.currentSymbol.marginType}
                                leverage = {accInfo.currentSymbol.leverage}
                                availableBalance = {accInfo.availableBalance}
                                totalBalance = {accInfo.totalBalance}
                                symbol = {accInfo.currentSymbol.symbol}
                                markPrice={accInfo.currentSymbol.markPrice}
                                price_v={accInfo.currentSymbol.price}
                                setNewOpenOrder={setNewOpenOrder}
                                updatePosition={updatePosition}
                                platform={platform}
                                updateAccInfo={updateAccInfo}
                            />
                        </div>
                    </div>

                </div>
            </div>
           </WrapperTrading>
        )
    }

    return (
        <div className="text-center">
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
    )
}

// @ts-ignore
export default FuturesTrade;