import React from 'react';
import axios from 'axios';
import Modal from './Modal';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch } from "react-redux";
import LeverageBrackets from './LeverageBrackets';
import { BsRepeat } from "react-icons/bs";


type TradeFormProps = {
    marginType: string,
    leverage: string,
    availableBalance: number,
    totalBalance: number,
    symbol: string,
    price_v: number,
    markPrice: number,
    setNewOpenOrder: any,
    updatePosition: any,
    platform: string|undefined,
    updateAccInfo: any,
}


class TradeForm extends React.Component <TradeFormProps> {
    constructor(props) {
        super(props)
        this.state = {
            order_type: 'LIMIT',
            side: "",
            stop_price: "",
            price: "",
            priceCurrent: "",
            size: "",
            take_profit: "",
            stop_loss: "",
            tp_sl: 0,
            reduceOnly: false,
            error: '',
            redirect: false,
            modalActive: false,
            modalMarginActive: false,
            modalLeverageActive: false,
            margin_mode_temp: "",
            leverage_temp: "",
            tp_sl_mode: "Full",
            tp_ls_mode_title: "Entire Position",
        }

    }

    alertMessage = (type: string, message: string) => {
        const notify = (type: string, message: string) => {
            if (type === 'success'){
                toast.success(message, {
                    position: toast.POSITION.BOTTOM_RIGHT
                });
            }
            if (type === 'error'){
                toast.error(message, {
                    position: toast.POSITION.BOTTOM_RIGHT
                });
            }
            if (type === 'warn'){
                toast.warn(message, {
                    position: toast.POSITION.BOTTOM_RIGHT
                });
            }
        }
        return notify(type, message)
    }

    handleChange = event => {
        const {name, value} = event.target
        this.setState({
            [name]: value
        })
    }

    change_tp_sl_mode = () => {
        let title = ''
        let mode = ''
        if (this.state.tp_ls_mode_title === 'Entire Position'){
            title = 'Current Order'
            mode = 'Partial'
        } else {
                title = 'Entire Position'
                mode = 'Full'
            }
        this.setState({
            tp_ls_mode_title: title,
            tp_sl_mode: mode
        })
    }

    orderConfirmation = async () => {
        const symbol = this.props.symbol
        const platform = this.props.platform
        let {order_type, side, stop_price, price, size, take_profit, stop_loss, tp_sl, reduceOnly, tp_ls_mode_title, tp_sl_mode} = this.state;
        const percent = (this.state.size*this.state.price/this.props.availableBalance*100).toFixed(2)
        const percent_marg = (this.state.size*this.state.price/this.props.availableBalance/this.props.leverage*100).toFixed(3)
        const marg = (this.state.size*this.state.price/this.props.leverage).toFixed(2)

        if (order_type === "MARKET"){
            price = this.props.price_v
        }
        this.setState({
            modalActive: false,
        })
        try {
            const response = await axios.post(`${platform}/futures/order/${symbol}/`, {
                order_type: order_type,
                side: side,
                stop_price: stop_price,
                price: price,
                size: size,
                take_profit: take_profit,
                stop_loss: stop_loss,
                tp_sl: tp_sl,
                reduceOnly: reduceOnly,
                percent: percent_marg,
                leverage: this.props.leverage,
                tp_sl_mode: tp_sl_mode,
            });

            console.log('order: ',response.data);
            if (response.data.trader.orderId) {
                this.alertMessage('success', `${response.data.trader.type}, ${response.data.trader.symbol}, ${response.data.trader.side},
                    ${response.data.trader.origQty} ${response.data.trader.symbol.replace('USDT', '')},
                    ${response.data.trader.price} USDT`
                );
                if (response.data.trader.status === "NEW"){
                    if (response.data.trader.type==="LIMIT") {
                        this.props.setNewOpenOrder(response.data.trader);
                    }
                    if (response.data.trader.type==="MARKET") {
                        this.props.updatePosition();
                    }
                }
            }
            else {
                this.alertMessage('error', `${response.data.trader.msg}`)
            }
            if (response.data.subscribers.count_subscribers == 0) {
                this.alertMessage('warn', `Subscribers: ${response.data.subscribers.count_subscribers}`);
            }
            if (response.data.subscribers.count_subscribers != response.data.subscribers.orders.length) {
                this.alertMessage('warn', `Ордера НЕ исполнены у ${response.data.subscribers.count_subscribers-response.data.subscribers.orders.length}
                    подписчиков из ${response.data.subscribers.count_subscribers}!`);
            }
            if (response.data.subscribers.orders.length) {
                if (response.data.subscribers.count_subscribers == response.data.subscribers.orders.length) {
                    const dateTime = (time: number) => {
                        return new Intl.DateTimeFormat('en-US', {year: 'numeric', month: '2-digit',day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: false,}).format(time)
                    }
                    const msg = () => {
                        let orders = ''
                        response.data.subscribers.orders.map(
                            (order) => {
                                orders = `${orders} ${dateTime(order.updateTime)}, ${order.symbol}, ${order.side}, ${order.origQty}, ${order.price} USDT`
                            }
                        )
                        return orders
                    }

                    this.alertMessage('success', `Ордера исполнены у всех ${response.data.subscribers.count_subscribers} подписчиков!`);
                    this.alertMessage('success', `${msg()}`);
                }
            }

        } catch (e) {
            this.alertMessage('error', `${e.message}`)
            this.setState({
                error: e.message,
            })
            console.log('errorOpenOrder:', this.state.error)
            console.log('errorOpenOrder', e)
          }

    }

    setMarginMode = async (margin_mode: string) => {
        const platform = this.props.platform
        try {
            const response = await axios.post(`${platform}/futures/set_margin/`, {
                margin_mode: margin_mode,
            });
            this.setState({modalMarginActive: false})
//             console.log('margin_mode: ',response.data);
            if (response.data.trader.retMsg.includes('Error')) {
                this.alertMessage('error', `${response.data.trader.retMsg}`);
            }
            else {
                this.alertMessage('success', `${response.data.trader.retMsg}`)
            }

        } catch (e) {
            this.alertMessage('error', `${e.message}`)
            this.setState({
                error: e.message,
            })
            console.log('errorMargin:', this.state.error)
            console.log('errorMargin', e)
          }

    }

    changeLeverage = (leverage: string) => {
        this.setState({leverage_temp: leverage})
    }

    setLeverage = async (leverage: string) => {
        const symbol = this.props.symbol
        const platform = this.props.platform
        try {
            const response = await axios.post(`${platform}/futures/set_leverage/${symbol}/`, {
                leverage: leverage,
            });
            this.setState({modalLeverageActive: false})
//             console.log('margin_mode: ',response.data);
            if (response.data.trader.retMsg.includes('Error')) {
                this.alertMessage('error', `${response.data.trader.retMsg}`);
            }
            if (response.data.trader.retMsg.includes('OK')) {
                this.alertMessage('success', `${response.data.trader.retMsg}! Leverage has been updated!`);
                this.props.updateAccInfo(platform, symbol)
            }
            else {
                this.alertMessage('warn', `${response.data.trader.retMsg}`)
            }

        } catch (e) {
            this.alertMessage('error', `${e.message}`)
            this.setState({
                error: e.message,
            })
            console.log('errorLeverage:', this.state.error)
            console.log('errorLeverage', e)
          }

    }

    setModalActive = (flag: boolean) => {
         this.setState({
            modalActive: flag
         })
    }
    handleSubmit = async (event) => {
        event.preventDefault()
        this.setModalActive(true);
    }
    marginType(){
        const border_red = {
            border: '1px solid rgba(255, 0, 0, 1.0)',
            cursor: 'pointer',
        };
         const border_none = {
            border: '0px solid rgba(255, 0, 0, 1.0)',
            cursor: 'pointer',
        };
        return (
            <div className="container">
	            <h5>Margin</h5>
                <hr/>
	            <div className="row">
                    <div className="col" style={this.state.margin_mode_temp === 'Isolated' ? border_red : border_none}
                        onClick={() => this.setState({margin_mode_temp: 'Isolated'})}
                    >
                      <h6>ISOLATED</h6>
                    </div>
                    <div className="col" style={this.state.margin_mode_temp === 'Cross' ? border_red : border_none}
                        onClick={() => this.setState({margin_mode_temp: 'Cross'})}
                    >
                      <h6>CROSSED</h6>
                    </div>
	            </div>
	            <p></p>
                <button className="button_long" onClick={() => this.setMarginMode(this.state.margin_mode_temp)}>Save</button> &nbsp;&nbsp;
                <button className="button_grey" onClick={() => this.setState({modalMarginActive: false})}>Cancel</button>
            </div>
        )
    }

    leverage() {
        const symbol = this.props.symbol;
        const platform = this.props.platform
        const leverage = this.state.leverage_temp
        return (
            <div className="container">
	            <h5>Leverage</h5>
                <hr/>
	            <div className="row">
                    <div className="col">
                        <LeverageBrackets
                            changeLeverage={this.changeLeverage}
                            leverage={leverage}
                            platform={platform}
                            symbol={symbol}
                        />
                    </div>

	            </div>
	            <p></p>
                <button className="button_long" onClick={() => this.setLeverage(this.state.leverage_temp)}>Save</button> &nbsp;&nbsp;
                <button className="button_grey" onClick={() => this.setState({modalLeverageActive: false})}>Cancel</button>
            </div>
        )
    }
    sendModal(){
        const {order_type, side, stop_price, price, size, take_profit, stop_loss, tp_sl, reduceOnly, tp_ls_mode_title, tp_sl_mode} = this.state;
        const marg = (size*price/this.props.leverage).toFixed(2)

	    return (
	        <div className="container">
	            <h5>Order Confirmation</h5>
                <hr/>
	            <div className="row">
                    <div className="col">
                      <h6>{this.props.symbol}</h6>
                    </div>
                    <div className="col">
                      <h6><span className={side==="BUY" ? "green" : "red"}>{side}</span></h6>
                    </div>
	            </div>
	            <div className="row">
                    <div className="col">
                      Price
                    </div>
                    <div className="col">
                      {order_type==="MARKET" ? "Market" : price + " USDT"}
                    </div>
	            </div>
	            <div className="row">
                    <div className="col">
                      Order Qty
                    </div>
                    <div className="col">
                      {(size*price).toFixed(2)} USDT <br/>({size} {this.props.symbol.replace('USDT', '')})
                    </div>
	            </div>
	             <div className="row">
                    <div className="col">
                      Margin
                    </div>
                    <div className="col">
                      {marg} USDT
                    </div>
	            </div>
	            <hr/>
	            {(((tp_sl>0) && (take_profit) || (tp_sl>0) && (stop_loss))) &&(

                        <div className="row">
                            <div className="col">
                                TP/SL applicable to:
                            </div>
                            <div className="col">
                                <b>{tp_ls_mode_title}</b>
                            </div>
                        </div>
                    )
                }
	            {((tp_sl>0) && (take_profit)) &&(
                        <div className="row">
                            <div className="col">
                              TP
                            </div>
                            <div className="col">
                              {take_profit} USDT
                            </div>
                        </div>
                    )
                }
                {((tp_sl>0) && (stop_loss))&&(
                        <div className="row">
                            <div className="col">
                              SL
                            </div>
                            <div className="col">
                              {stop_loss} USDT
                            </div>
                        </div>
                    )
	            }
	            <p></p>
                <button className={side==="BUY" ? "button_long" : "button_short"} onClick={this.orderConfirmation}>{side}</button> &nbsp;&nbsp;
                <button className="button_grey" onClick={() => this.setModalActive(false)}>Cancel</button>
            </div>
	    )
    }

    _tpsl= () => {
        let tp_sl = this.state.tp_sl
        tp_sl = tp_sl !== 1 ? 1: tp_sl - 1
        this.setState({
            tp_sl: tp_sl
        })
        if (tp_sl = 1){
            this.setState({
                take_profit: "",
                stop_loss: "",
            })
        }

    }

    _limit= () => {
        let order_type = this.state.order_type
        order_type = 'LIMIT'
        this.setState({
          order_type: order_type
        })
    }

    _market= () => {
        let order_type = this.state.order_type
        order_type = 'MARKET'
        this.setState({
          order_type: order_type
        })
    }

    _stop_limit= () => {
        let order_type = this.state.order_type
        order_type = 'STOP_LIMIT'
        this.setState({
          order_type: order_type
        })
    }

    _long= () => {
        let side = this.state.side
        side = 'BUY'
        this.setState({
          side: side
        })
    }

    _short= () => {
        let side = this.state.side
        side = 'SELL'
        this.setState({
          side: side
        })
    }

    _reduceOnly= () => {
        let reduceOnly = this.state.reduceOnly
        if (reduceOnly) {
            reduceOnly = false
        }
        else {
            reduceOnly = true
        }
        this.setState({
          reduceOnly: reduceOnly
        })
    }

    sendLongButton(){
			return (
				<button className="button_long" type="submit" onClick={this._long}>Buy</button>
			)
    }

    sendShortButton(){
			return (
				<button className="button_short" type="submit" onClick={this._short}>Sell</button>
			)
		}
//     componentDidMount = () => {
//         alert('componentDidMount', this.props.price_v)
//         console.log('price props', this.props.price_v)
//         this.setState({
//             price: this.props.price_v,
//
//         })
//     }

	render() {
	    if (this.props.price_v !== this.state.priceCurrent){
	        this.setState({
                priceCurrent: this.props.price_v,
                price: this.props.price_v,
            })
	    }

	    const percent_pos = (this.state.size*this.state.price/this.props.availableBalance*100).toFixed(2)
	    const percent_marg = (this.state.size*this.state.price/this.props.availableBalance/this.props.leverage*100).toFixed(2)
	    const marg_balance = (this.props.availableBalance*this.props.leverage).toFixed(2)
	    const cost = (this.state.size*this.state.price).toFixed(2)
	    const marg = (this.state.size*this.state.price/this.props.leverage).toFixed(2)

	    if (this.state.error.includes("403")) {
            return (
                <div className="form_row">
                     <a href="/login">Login</a> or <a href="/register">Register Now</a> to trade
                </div>
            )
        }
		return (
		  <div className="form_row">
              <button type="button" className="btn btn-secondary btn-sm" onClick={()=> this.setState({modalMarginActive: true, margin_mode_temp: this.props.marginType})}>
              {this.props.marginType}</button>&nbsp;
              <button type="button" className="btn btn-secondary btn-sm" onClick={()=> this.setState({modalLeverageActive: true, leverage_temp: this.props.leverage})}>
              {this.props.leverage}x</button>
              <hr/>

              <div className="order_tabs">
                <div className={this.state.order_type==='LIMIT' ? "order_tabs_title_active" : "order_tabs_title"} onClick={this._limit}>Limit</div>
                <div className={this.state.order_type==='MARKET' ? "order_tabs_title_active" : "order_tabs_title"} onClick={this._market}>Market</div>
                {//<div className="order_tabs_title" onClick={this._stop_limit}></div>
                }
              </div>

              <br/>
              Totalbl {this.props.totalBalance} USDT <br/>
              Avbl {this.props.availableBalance} USDT

              <form onSubmit={this.handleSubmit}>
                <StopPrice
                  order_type={this.state.order_type}
                  handleChange={this.handleChange}
                  stop_price={this.state.stop_price}
                />
                <Price
                  order_type={this.state.order_type}
                  handleChange={this.handleChange}
                  price={this.state.price}
                />
                <Size
                  order_type={this.state.order_type}
                  handleChange={this.handleChange}
                  size={this.state.size}
                />
                Cost: {cost} USDT ({percent_pos}%)<br/>
                Marg: {marg} USDT ({percent_marg}%)

{/*                 <div className="form-range"> */}
{/*                 <label htmlFor="customRange2" className="form-label"></label> */}
{/*                 <input type="range" className="form-range" list="values" id="customRange2"/> */}
{/*                 <datalist id="values"> */}
{/*                   <option value="0" label="0"></option> */}
{/*                   <option value="25" label="25"></option> */}
{/*                   <option value="50" label="50"></option> */}
{/*                   <option value="75" label="75"></option> */}
{/*                   <option value="100" label="100"></option> */}
{/*                 </datalist> */}
{/*                 </div> */}
                <hr/>
                <div className="form-check">
                  <input /* disabled */ className="form-check-input" type="checkbox" name="tp_sl" value="tp_sl" onChange={this._tpsl}/>
                  <label className="form-check-label" htmlFor="tp_sl"> TP/SL</label>
                </div>
                <TP_SL
                  tp_sl={this.state.tp_sl}
                  handleChange={this.handleChange}
                  take_profit={this.state.take_profit}
                  stop_loss={this.state.stop_loss}
                  change_tp_sl_mode={this.change_tp_sl_mode}
                  tp_ls_mode_title={this.state.tp_ls_mode_title}
                />
                <div className="form-check">
                  <input className="form-check-input" type="checkbox" name="reduce-only" value="reduce-only" onChange={this._reduceOnly}/>
                  <label className="form-check-label" htmlFor="reduce-only"> Reduce-Only</label>
                </div>
                <hr/>
                <p>
                {this.sendLongButton()}
                {this.sendShortButton()}
                </p>
              </form>

              {this.state.modalActive && (
                <Modal active={this.state.modalActive} setActive={this.setModalActive}>
                    {this.sendModal()}
                </Modal>
              )}
              {this.state.modalMarginActive && (
                <Modal active={this.state.modalMarginActive} setActive={()=> this.setState({modalMarginActive: false})}>
                    {this.marginType()}
                </Modal>
              )}
              {this.state.modalLeverageActive && (
                <Modal active={this.state.modalLeverageActive} setActive={()=> this.setState({modalLeverageActive: false})}>
                    {this.leverage()}
                </Modal>
              )}

              <ToastContainer/>

		  </div>
		);
	  }
	}

class StopPrice extends React.Component {

		render() {
		  if (this.props.order_type !== 'STOP_LIMIT') {
			return null;
		  }
		  return(
			<div>

			  <input className="form-control"
				name="stop_price"
				type="number"
				placeholder="Stop Price"
				value={this.props.stop_price}
				onChange={this.props.handleChange}
				autocomplete="off"
				min="0"
				step="0.00001"
				/>
			</div>
		  );
		}
}
class Price extends React.Component<{order_type: string, price: number, handleChange: any}> {

		render() {
    	if (this.props.order_type === 'MARKET') {
			return null;
		  }

		return(
            <div>
              <input className="form-control"
                name="price"
                type="number"
                placeholder="Price"
                value={this.props.price}
                onChange={this.props.handleChange}
                autocomplete="off"
                min="0"
                step="0.000001"
                inputmode="numeric"
                />
{/*                 <div><a href='#last' onClick={this.last_price()}>Last</a></div> */}
            </div>
			);
		}
}


class Size extends React.Component {
		render() {

		  return(
			<div>

			  <input className="form-control"
				name="size"
				type="number"
				placeholder="Size"
				value={this.props.size}
				onChange={this.props.handleChange}
				required
				autocomplete="off"
				min="0"
			    step="0.0001"
				/>
			</div>
		  );
		}
}

class TP_SL extends React.Component {
		render() {
		  if (this.props.tp_sl !== 1) {
			return null
		  }
		  return(

			<div>
              Applicable to: <span onClick={this.props.change_tp_sl_mode} className="tp_sl_mode_active">{this.props.tp_ls_mode_title} <BsRepeat/></span>
			  <input className="form-control"
				name="take_profit"
				type="number"
				placeholder="Take Profit"
				value={this.props.take_profit}
				onChange={this.props.handleChange}
				min="0"
				step="0.000001"
              />

			  <input className="form-control"
				name="stop_loss"
				type="number"
				placeholder="Stop Loss"
				value={this.props.stop_loss}
				onChange={this.props.handleChange}
				min="0"
				step="0.000001"
              />
			</div>
		  );
		}
}


export default TradeForm