import React, {Component, useEffect} from 'react';
import {Navigate, useNavigate, Link} from 'react-router-dom';
import {User} from "../models/user";
import { connect } from 'react-redux';
import axios from 'axios';

const AuthUser: React.FC<{handleClick: any}> = (props) => {
    const {handleClick} = props;
//     if (username) {
        return (
            <a className="nav-link" href="#" onClick={handleClick}>Sign out</a>
        )
//     }
//     return (
//             <a className="nav-link" href="#" onClick={handleClick}>Log in</a>
//         )
}

// const RefreshPage = () => {
//     const navigate = useNavigate();
//     useEffect(()=> {
//         navigate(0);
//     }, []);
//     return null
// }

class Nav extends Component<{user: User}> {
    state = {
        redirect: false,
        reload: false
    }
    handleClick = async () => {
//         if (this.props.user.name) {
            await axios.post('logout', {});
            this.setState({
                redirect: true
            })
//         }
//         else {
//             this.setState({
//                 redirect: true
//             })
//         }
    }

    render() {
//         if (this.state.reload) {
//             return <RefreshPage/>
//         }

        if (this.state.redirect) {
            return <Navigate to={'/login'}/>
        }

        return (
            <header className="navbar navbar-expand-md navbar-dark bg-dark" data-bs-theme="dark">


                <div className="container-fluid">
                  <a className="navbar-brand col-md-3 col-lg-2 me-0 px-3 fs-6 text-white" href="/">Copytrading</a>
                  <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                  </button>
                  <div className="collapse navbar-collapse" id="navbarCollapse">
                    <ul className="navbar-nav me-auto mb-2 mb-md-0">
                      <li className="nav-item">
                        <Link className="nav-link active" aria-current="page" to="/futures">Futures</Link>
                      </li>


                    </ul>
                    <div className="d-flex">
                            <div className="dropdown">
                            <Link to="/my/dashboard">
                                <img className="icons" src={process.env.PUBLIC_URL + '/icons/profil.png'}/>
                            </Link>

                            <div className="dropdown-content">

                                <Link to="/my/api-managment">API managment</Link>
                                <AuthUser
                                    handleClick={this.handleClick}
                                />
                            </div>
                            </div>


                    </div>
                  </div>
                </div>
            </header>

        )
    }
}

// @ts-ignore
export default connect(state => ({user: state.user}))(Nav);
