import React, {useState, useEffect, ChangeEvent } from 'react';
import axios from 'axios';
import Modal from './Modal';
import alertMessage from "./Alert";
import { BsPencilSquare } from "react-icons/bs";
import SendSelect from "./Select";

interface VariablesProps {
    api_keys_id: number;
    platform: string;
    client: string;
}
const DataInit = {
        id: 0,
        trading_platform: "",
        api_key: "",
        secret_key: "",
        user_id: 0,
        account_type: ""
    }
const EditAPIKeys: React.FC<VariablesProps> = (props) => {
    const {api_keys_id, platform, client} = props;
    const [error, setError] = useState('');
    const [showModalEditAPIKeys, setShowModalEditAPIKeys] = useState(false);
    const [title, setTitle] = useState('');
    const [api_key, setAPIKey] = useState('');
    const [secret_key, setSecretKey] = useState('');
    const [account_type, setAccountType] = useState('');
    const [data_keys, setDataKeys] = useState(DataInit);
    const [change_acc_type, setChangeAccType] = useState(false);
    const account_options = [{'value': 'CONTRACT', 'label':'CONTRACT'}, {'value': 'UNIFIED', 'label':'UNIFIED'}]

    const getApiKeys = async () => {
        try {
            const response = await axios.get(`/client_api/${api_keys_id}`);

            if (response.data.data.id) {
                setDataKeys(response.data.data)
                setAPIKey(response.data.data.api_key)
                setSecretKey(response.data.data.secret_key)
                setAccountType(response.data.data.account_type)

            }
            else {
                alertMessage('error', `${response.data.data}`)
                setError(response.data.data)
            }



        } catch (e:any) {
            alertMessage('error', `${e.message}`)
            setError(e.message)
            console.log('Error get API Keys:', error)
            console.log('Error get API Keys:', e)
          }
    }
    const editAPIKeysConfirmation = async () => {
console.log(account_type, data_keys.account_type)
        if (api_key === data_keys.api_key && secret_key === data_keys.secret_key && account_type === data_keys.account_type){
            alertMessage('warn', 'Not changed!')
            return
        }
        setShowModalEditAPIKeys(false)
        try {
            const response = await axios.put(`client_api`, {
                api_keys_id: api_keys_id,
                api_key: api_key,
                secret_key: secret_key,
                account_type: account_type,
            });

            if (response.data.id) {
                alertMessage('success', `Changed API Keys success!`);
            }
            else {
                alertMessage('error', `${response.data.data.msg}`)
            }

        } catch (e:any) {
            alertMessage('error', `${e.message}`)
            setError(e.message)
            console.log('Error Change API Keys:', e.response.data)
          }

    }
    const no_change = () => {
        return (
            <>
                <label className="form-label">Account Type</label>
                <input className="form-control"
                    name="at"
                    value={account_type}
                    readOnly
                />
                <span className="change_input" onClick={()=>setChangeAccType(true)}>Change</span>
            </>
        )
    }

    const to_change = () => {
        return (
            <>
                <label className="form-label">Account Type</label>
                <SendSelect
                    name="acc_type"
                    options={account_options}
                    setChange={(name: string, e: any) => setAccountType(e.value)}
                    placeholder="Select Account Type"
                />
            </>
            )
    }
    const show_confirm_edit_apikeys = () => {

        return (
             <div className="container">
	            <h5>{title} </h5> {platform} {client}
                <hr/>
	            <div className="row">
	               {platform.includes('Bybit')&&(
                        <div className="mb-3">
                            {change_acc_type ? to_change() : no_change()}
                        </div>
                   )}


                    <div className="mb-3">
                        <label className="form-label">API Key</label>
                        <input className="form-control"
                            name="ak"
                            value={api_key}
                            onChange={e => setAPIKey(e.target.value)}
                            autoComplete="off"
                        />
                    </div>

	                <div className="mb-3">
                        <label className="form-label">Secret Key</label>
                        <input className="form-control"
                            name="sk"
                            value={secret_key}
                            onChange={e => setSecretKey(e.target.value)}
                            autoComplete="off"
                        />

                    </div>

	            </div>
	            <p></p>
                <button className="button_long" onClick={()=>editAPIKeysConfirmation()}>Confirm</button> &nbsp;&nbsp;
                <button className="button_grey" onClick={() => setShowModalEditAPIKeys(false)}>Cancel</button>

            </div>
        )
    }
    const editAPIKeys = () => {
        getApiKeys()
        setShowModalEditAPIKeys(true)
        setChangeAccType(false)
        setTitle('Change API Keys')
    }

    return (
        <>
        <a href="#edit" onClick={()=>{editAPIKeys()}}><BsPencilSquare /></a>
        {showModalEditAPIKeys && (
                <Modal
                    active={showModalEditAPIKeys}
                    setActive={setShowModalEditAPIKeys}
                >
                    {show_confirm_edit_apikeys()}
                </Modal>
        )}
        </>
    )
}
export default EditAPIKeys;