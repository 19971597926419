import React, {Component} from 'react';
import axios from "axios";
import { BsTrash } from "react-icons/bs";
import alertMessage from "./Alert";

type CancelOrder = {
    status: string,
    orderId: string,
}

const alertNotifY = (type: string, msg: string) => {
    return alertMessage(type, msg)
}

class CancelOpenOrder extends Component<{orderId: string, endpoint: string, handleCancelOrder: any}> {
    cancel = async () => {
        if (window.confirm('Are you sure you want to delete this record?')) {
            const response = await axios.delete(`${this.props.endpoint}/${this.props.orderId}`);
            console.log('CancelOpenOrder response: ', response.data)
            if (response.data.order_trader.status === 'CANCELED'){
                this.props.handleCancelOrder(this.props.orderId);
                alertNotifY('success', 'Order trader was successfully canceled!')
            } else {
                    alertNotifY('error', response.data.order_trader.msg)
                }
            if (response.data.orders_subscribers.length){
                const count_canceled_orders_subscribers = response.data.orders_subscribers.length
                let i = 0
                response.data.orders_subscribers.map(
                    (order: CancelOrder) => {
                        if (order.status === 'CANCELED'){
//                             this.props.handleCancelOrder(order.orderId);
                            i = i + 1
                            alertNotifY('success', 'Subscriber orders have been canceled!')
                        }
                        else {
                            alertNotifY('error', 'Subscriber orders were not canceled!')
                        }
                    }
                )
                if (i === count_canceled_orders_subscribers){
                    alertNotifY('success', `Subscriber orders ${count_canceled_orders_subscribers} have been canceled successfully!`)
                }
                else {
                    alertNotifY('warn', `Subscriber orders ${count_canceled_orders_subscribers-i} were not canceled!`)
                }
            }
        }
    }

    render() {
        return (
        <>
            <BsTrash onClick={() => this.cancel()}/>
        </>
        );
    }
}

export default CancelOpenOrder;