
const Modal = (props: any) => {
    const {active, setActive, style, children} = props

    return (
    <>
        <div className={active ? "modal active" : "modal"} onClick={() => setActive(false)}>
            <div className={active ? "modal_content active" : "modal_content"} style={style} onClick={e => e.stopPropagation()}>
                {children}
            </div>
        </div>
    </>
    )
}

export default Modal;