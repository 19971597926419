import React, {Component, SyntheticEvent} from 'react';
import Wrapper from '../../components/Wrapper';
import {connect} from "react-redux";
import {User} from "../../models/user";
import {ClientApi} from "../../models/clientsApi";
import axios from 'axios';
import {Navigate, Link} from 'react-router-dom';
import Deleter from "../../components/Deleter";
import SendSelect from "../../components/Select";


type typeService = {
    id: string,
    name: string,
}

type selectOptions = {
    value: string,
    label: string,
}

class ApiClientsKeys extends Component<{user: User}> {

    state = {
        trading_platform: '',
        api_key: '',
        secret_key: '',
        platform: '',
        email: '',
        account_type: '',
        add_link: true,
        add_form: false,
        clients_api: [],
        error: '',
        optionsServiceName: [],
        account_options: [{'value': 'CONTRACT', 'label':'CONTRACT'}, {'value': 'UNIFIED', 'label':'UNIFIED'}],
    }

    componentDidMount = async () => {
        try {
            const response = await axios.get(`client_api`);
            this.setState({
                clients_api: response.data
            })
        } catch (e:any) {
                console.log("Error:", {e})
                if (e.message.includes("403")){
                    this.setState({
                        error: '403 Forbidden'
                    })
                }
        }
    }

    submit = async (e: SyntheticEvent) => {
        e.preventDefault();
        try {
            const response = await axios.post('create_user_and_api', {
                trading_platform: this.state.trading_platform,
                email: this.state.email,
                api_key: this.state.api_key,
                secret_key: this.state.secret_key,
                account_type: this.state.account_type,
            });
            this.setState({
                clients_api: [...this.state.clients_api, response.data],
//                 id_key: response.data.data.id,
//                 trading_platform: response.data.data.trading_platform,
//                 api_key: response.data.data.api_key,
//                 secret_key: response.data.data.secret_key,
                error: '',
//                 add: false
            })
            this.link_form();
//             console.log(response);

         } catch (e:any) {
                console.log("Error:", {e})
                if (e.message.includes("403")){
                    this.setState({
                        error: '403 Forbidden'
                    })
                } else {
                    this.setState({
                        error: 'Invalid or non-unique entered data, please try again!'
                    })
                }
            }
    }

    getOptions = async () => {
        try {
            let items: selectOptions[] = []

            const responseService = await axios.get('binance/futures/subscriptions/options/');
            if (responseService.data.length) {
                responseService.data.map(
                    (service: typeService) => {
                         let item: selectOptions = {
                            value: service.id,
                            label: service.name
                         }
                         items = [...items, item]
                    }
                )
                this.setState({
                    optionsServiceName: items,
                })
                items = []
            }

        } catch (e:any) {
                console.log("Error:", {e})
                if (e.message.includes("403")){
                    this.setState({
                        error: '403 Forbidden'
                    })
                } else {
                    this.setState({
                        error: e.message
                    })
                }
            }
    }

    handleChange = async (name: string, e: any) => {
        if (e){
            this.setState({[name]: e.label}) // витягую назву платформи Binance, Bybit
        }
    }

    handleDelete = async (id: number) => {
        this.setState({
            clients_api: this.state.clients_api.filter((api: ClientApi) => api.id !== id)
        })
    }

    actions = (id: number) => {
        if (this.props.user.role) {
            return (
                <div className="btn-group mr-2">
                    <Deleter id={id} endpoint={'client_api'}
                             handleDelete={this.handleDelete}/>
                </div>
            )
        }
    }

    link_form = async() => {
        let add_link = this.state.add_link
        let add_form = this.state.add_form
        add_link = add_link ? false: true
        add_form = add_form ? false: true
        this.setState({
          add_link: add_link,
          add_form: add_form
        })
        if (add_form){
            await this.getOptions();
        }
	}

    addLinkForm = () => {
        if (this.props.user.role && this.state.add_link)
            return (
                <>
                    <Link className="nav-link d-flex align-items-center gap-2" to="#add-client-api" onClick={this.link_form}>
                        Add client API
                    </Link>
                    <p></p>
                </>
            )
        if (this.props.user.role && this.state.add_form)
            return (
                <>
                    <form className="container" onSubmit={this.submit}>
                        <h4 className="h5 mb-3 fw-normal">Please enter data client below</h4>
                        <div className="frow mb-2">
                            <SendSelect
                                    name="trading_platform"
                                    options={this.state.optionsServiceName}
                                    setChange={this.handleChange}
                                    placeholder="Select Trading platform"
                            />
                            </div>
                        <div className="row mb-2">
                             <SendSelect
                                    name="account_type"
                                    options={this.state.account_options}
                                    setChange={this.handleChange}
                                    placeholder="Select Account Type"
                            />
                        </div>
                        <div className="form-floating">
                          <input type="email" className="form-control" id="inputEmail" placeholder="Email client"
                          required onChange = {e => this.state.email = e.target.value} />
                          <label style={{zIndex: '0'}} htmlFor="trading_platform">Email client</label>
                        </div>
                        <div className="form-floating">
                          <input type="text" className="form-control" id="api_key" placeholder="Api Key"
                          required onChange = {e => this.state.api_key = e.target.value} />
                          <label style={{zIndex: '0'}} htmlFor="api_key">Api Key</label>
                        </div>
                        <div className="form-floating">
                          <input type="password" className="form-control" id="secret_key" placeholder="Secret Key"
                          required onChange = {e => this.state.secret_key = e.target.value}/>
                          <label htmlFor="secret_key">Secret Key</label>
                        </div>
                        <div className="form-error">
                        {this.state.error}
                        </div>
                        <button className="btn btn-primary w-20 py-2" type="submit">Save</button>&nbsp;&nbsp;
                        <button className="btn btn-secondary w-20 py-2" onClick={this.link_form}>Cancel</button>
                        <p></p>
                    </form>
                </>
            )
    }
    render() {
        if (!this.props.user.role) {
            return <Navigate to='/my/dashboard'/>
        }
        if (this.state.error.includes('403')){
            return <Navigate to='/login'/>
        }

        return (
            <Wrapper>
                <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                    <h1 className="h2">API clients managment</h1>
                </div>
                {this.addLinkForm()}

                <div className="table-responsive small">
                {this.state.clients_api.length >0 && (
                    <table className="table table-striped table-sm">
                      <thead>
                        <tr>
                          <th scope="col">#</th>
                          <th scope="col">Client</th>
                          <th scope="col">Trading Platform</th>
                          <th scope="col">Account type</th>
                          <th scope="col">Api Key</th>
                          <th scope="col">Secret Key</th>
                          <th scope="col">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.clients_api.map(
                            (api: ClientApi) => {
                                return (
                                    <tr key={api.id}>
                                        <td>{api.id}</td>
                                        <td>{api.user_id}</td>
                                        <td>{api.trading_platform}</td>
                                        <td>{api.account_type}</td>
                                        <td>{api.api_key}</td>
                                        <td>**************</td>
                                        <td>{this.actions(api.id)}</td>
                                    </tr>
                                )
                            }
                        )}
                      </tbody>
                    </table>
                )}
                {!this.state.clients_api.length && <span>Client api keys are missing!</span>}

                </div>


            </Wrapper>
        )
    }
}

// @ts-ignore
export default connect(state => ({user: state.user.user}))(ApiClientsKeys);