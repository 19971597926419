import React, {useState} from 'react';
import axios from 'axios';
import Modal from './Modal';
import alertMessage from "./Alert";
import { BsPencilSquare } from "react-icons/bs";

interface VariablesProps {
    takeProfit: number;
    stopLoss: number;
    symbol: string;
    platform: string;
    order_id?: string;
    client_id?: number;
    updatePosition?: any;
    getStatus?: any;
    is_position?: boolean;
}

const EditTPSL: React.FC<VariablesProps> = (props) => {
    const {takeProfit, stopLoss, symbol, order_id, platform, client_id, getStatus, updatePosition, is_position} = props;
    const [error, setError] = useState('');
    const [showModalEditTPSL, setShowModalEditTPSL] = useState(false);
    const [title, setTitle] = useState('');
    const [take_profit, setTakeProfit] = useState('');
    const [stop_loss, setStopLoss] = useState('');

    const tpslConfirmation = async () => {

        if (Number(take_profit) === Number(takeProfit) && Number(stop_loss) === Number(stopLoss)){
            alertMessage('warn', 'Not changed!')
            return
        }

        let _take_profit = "0"
        let _stop_loss = "0"
        if (take_profit) {_take_profit = take_profit}
        if (stop_loss) {_stop_loss = stop_loss}

        let endpoint = `${platform}/futures/order/${symbol}/`
        if (client_id){
                endpoint = `${platform}/futures/order/${symbol}/${client_id}`
            }

        setShowModalEditTPSL(false)
        try {
            const response = await axios.put(`${endpoint}`, {
                symbol: symbol,
                order_id: order_id,
                take_profit: _take_profit,
                stop_loss: _stop_loss,
            });

            console.log('tpsl: ',response.data);
            if (response.data.trader.retMsg==="OK") {
                alertMessage('success', `Changed TP/SL success!`);
                if (is_position){
                    if (client_id){
                            getStatus(client_id, platform)
                        } else {
                                updatePosition();
                            }
                }
            }
            else {
                alertMessage('error', `${response.data.trader.msg}`)
            }
            if (response.data.subscribers.count_subscribers === 0) {
                alertMessage('warn', `Subscribers: ${response.data.subscribers.count_subscribers}`);
            }
            if (response.data.subscribers.count_subscribers !== response.data.subscribers.orders.length) {
                alertMessage('warn', `TP/SL ордеров НЕ изменены у ${response.data.subscribers.count_subscribers-response.data.subscribers.orders.length}
                    подписчиков из ${response.data.subscribers.count_subscribers}!`);
            }
            if (response.data.subscribers.orders.length) {
                if (response.data.subscribers.count_subscribers === response.data.subscribers.orders.length) {
                    const dateTime = (time: number) => {
                        return new Intl.DateTimeFormat('en-US', {year: 'numeric', month: '2-digit',day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: false,}).format(time)
                    }
                    const msg = () => {
                        let orders = ''
                        response.data.subscribers.orders.map(
                            (order:any) => {
                                orders = `${orders} ${dateTime(order.updateTime)}, ${order.symbol}, ${order.side}, ${order.origQty}, ${order.price} USDT`
                            }
                        )
                        return orders
                    }

                    alertMessage('success', `TP/SL ордеров изменены у всех ${response.data.subscribers.count_subscribers} подписчиков!`);
//                     alertMessage('success', `${msg()}`);
                }
            }

        } catch (e:any) {
            alertMessage('error', `${e.message}`)
            setError(e.message)
            console.log('Error Change TP/SL:', error)
            console.log('Error Change TP/SL:', e)
          }

    }

    const show_confirm_edit_tpsl = () => {

        return (
             <div className="container">
	            <h5>{title} </h5> {symbol}
                <hr/>
	            <div className="row">
                    <div className="mb-3">
                        <label className="form-label">Take Profit</label>
                        <input className="form-control"
                            name="tp"
                            type="number"
                            value={take_profit}
                            onChange={e => setTakeProfit(e.target.value)}
                            autoComplete="off"
                            min="0"
                            step="0.0000001"
                        />
                    </div>

	                <div className="mb-3">
                        <label className="form-label">Stop Loss</label>
                        <input className="form-control"
                            name="sl"
                            type="number"
                            value={stop_loss}
                            onChange={e => setStopLoss(e.target.value)}
                            autoComplete="off"
                            min="0"
                            step="0.0000001"
                        />

                    </div>

	            </div>
	            <p></p>
                <button className="button_long" onClick={()=>tpslConfirmation()}>Confirm</button> &nbsp;&nbsp;
                <button className="button_grey" onClick={() => setShowModalEditTPSL(false)}>Cancel</button>

            </div>
        )
    }
    const editTPSL = (title: string) => {
        setShowModalEditTPSL(true)
        setTitle(title)
        setTakeProfit(takeProfit.toString())
        setStopLoss(stopLoss.toString())
    }

    const tp_sl = (tp: number, sl: number) => {
        return (
                <>
                <span className="green">{tp?tp:'--'}</span>/<span className="red">{sl?sl:'--'}</span> <a href="#edit" onClick={()=>{editTPSL('Change TP/SL')}}><BsPencilSquare /></a>
                </>
            )
    }

    return (
        <>
        {(takeProfit || stopLoss) ? tp_sl(takeProfit, stopLoss) : <button type="button" className="btn btn-secondary btn-sm" onClick={()=>{editTPSL('Add TP/SL')}}>+Add</button>}
        {showModalEditTPSL && (
                <Modal
                    active={showModalEditTPSL}
                    setActive={setShowModalEditTPSL}
                >
                    {show_confirm_edit_tpsl()}
                </Modal>
        )}
        </>
    )
}
export default EditTPSL;