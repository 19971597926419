import React, {Component, SyntheticEvent} from 'react';
import Wrapper from '../../components/Wrapper';
import {connect} from "react-redux";
import {User} from "../../models/user";
import axios from 'axios';
import {Navigate, Link} from 'react-router-dom';
import Deleter from "../../components/Deleter";
import TestConnect from "../../components/TestConnect";

class ApiKeys extends Component<{user: User}> {

    state = {
        id_key: 0,
        trading_platform: '',
        api_key: '',
        secret_key: '',
        account_type: '',
        add: false,
        error: '',
    }

//     componentDidMount = async () => {
    getUserApi = async (trading_platform: string) => {
        try {
            const response = await axios.get(`${trading_platform}/get_user_api`);

//             console.log('ApiKeys:', res.data)
//             if (response.data.data.api_key) {
//                 console.log('eeeee');
//             }
            this.setState({
                id_key: response.data.data.id,
                trading_platform: response.data.data.trading_platform,
                api_key: response.data.data.api_key,
                secret_key: response.data.data.secret_key,
                account_type: response.data.data.account_type,
            })

        } catch (e) {

//             this.setState({
//                 redirect: true
//             })
        }
    }

    submit = async (e: SyntheticEvent) => {
        e.preventDefault();
        try {
            const response = await axios.post('add_user_api', {
                trading_platform: this.state.trading_platform,
                api_key: this.state.api_key,
                secret_key: this.state.secret_key,
                account_type: this.state.account_type,
            });

            if (response.data.data.api_key) {
                this.setState({
                    id_key: response.data.data.id,
                    trading_platform: response.data.data.trading_platform,
                    api_key: response.data.data.api_key,
                    secret_key: response.data.data.secret_key,
                    account_type: response.data.data.account_type,
                    error: '',
                    add: false
                })
            }


         } catch (e:any) {
                console.log("Error:", {e})
                if (e.message.includes("403")){
                    this.setState({
                        error: '403 Forbidden'
                    })
                } else {
                    this.setState({
                        error: 'Invalid entered data, please try again!'
                    })
                }
            }
    }
    handleDelete = async (id_key: number) => {
        this.setState({
            id_key: 0
        })
    }
    handleAdd = async () => {
        this.setState({
            add: true
        })
    }
    actions = (id_key: number) => {
            const platform = this.state.trading_platform.toLowerCase().split(' ')[0]
//             console.log(platform)
//             if (this.props.user.canEdit('products')) {
            return (
                <div className="btn-group mr-2">
                    <Deleter id={id_key} endpoint={'/client_api'}
                             handleDelete={this.handleDelete}/>  &nbsp; | &nbsp;
                    <TestConnect
                        id={this.props.user.id}
                        endpoint={`${platform}/futures/account_info/BTCUSDT`}
                    />
                </div>
            )
//             }
    }

    goPlatform = async (platform: string) => {
        await this.getUserApi(platform)
        this.setState({
            trading_platform: platform,
            add: false,
            error: '',
        })
    }

    title_nav(){
			return (
			<div>
				<div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                        <h1 className="h2">API Managment</h1>
                </div>
                <ul className="nav nav-tabs">
                        <li className="nav-item">
                            <span className="nav-link" onClick={() => this.goPlatform('Binance Futures')}>Binance Futures</span>
                        </li>
                        <li className="nav-item">
                            <span className="nav-link" onClick={() => this.goPlatform('Bybit Futures')}>Bybit Futures</span>
                        </li>
                </ul>
                <p></p>
            </div>
			)
    }

    render() {
//         alert(`render id=${this.state.id_key} res ${this.state.id_key != undefined && this.state.id_key != 0}`);
        if (this.state.error.includes('403')){
            return <Navigate to='/login'/>
        }
        if (this.state.add && this.state.trading_platform) {
            return (
                <Wrapper>
                    {this.title_nav()}

                    <h4>{this.state.trading_platform}</h4>
                    <form onSubmit={this.submit}>
                        <h5 className="h5 mb-3 fw-normal">Please enter Api Key and Secret Key</h5>
                        {(this.state.trading_platform === 'Bybit Futures') && (
                            <div className="form-floating">
                                  <select className="form-control" id="account_type" placeholder="Account Type"
                                  required onChange = {e => this.state.account_type = e.target.value}>
                                    <option value="">--Choose type account--</option>
                                    <option value="CONTRACT">Classic</option>
                                    <option value="UNIFIED">Unified trading</option>
                                  </select>
                                  <label htmlFor="api_key">Account Type</label>
                            </div>
                        )}

                        <div className="form-floating">
                          <input type="text" className="form-control" id="api_key" placeholder="Api Key"
                          required onChange = {e => this.state.api_key = e.target.value} />
                          <label htmlFor="api_key">Api Key</label>
                        </div>
                        <div className="form-floating">
                          <input type="password" className="form-control" id="secret_key" placeholder="Secret Key"
                          required onChange = {e => this.state.secret_key = e.target.value}/>
                          <label htmlFor="secret_key">Secret Key</label>
                        </div>
                        <div className="form-error">
                        {this.state.error}
                        </div>
                        <button className="btn btn-primary w-100 py-2" type="submit">Add</button>
                    </form>

                </Wrapper>

            )
        }

        if (this.state.id_key != undefined && this.state.id_key != 0) {
            return (
                <Wrapper>
                    {this.title_nav()}
                    {this.state.trading_platform && (
                    <>
                        Exchange: <b>{this.state.trading_platform}</b><br/>
                        Account type: {this.state.account_type}<br/>
                        API key: {this.state.api_key}<br/>
                        Secret key: *********************************************************<br/>
                        {this.actions(this.state.id_key)}<br/>
                    </>
                    )}
                </Wrapper>

            )
        }
        return (
            <Wrapper>
                {this.title_nav()}
                {this.state.trading_platform && (
                    <>
                    <h4>{this.state.trading_platform}</h4>
                    <div>{this.props.user.name}, you don't have API keys yet!
                    <a href="#" onClick={this.handleAdd}>Add</a>
                    </div>
                    </>
                )}

            </Wrapper>
        )
    }
}

// @ts-ignore
export default connect(state => ({user: state.user.user}))(ApiKeys);