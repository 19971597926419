import React, { useState, ChangeEvent } from 'react';

import Select from 'react-select';


const SendSelect: React.FC<{placeholder: string, name: string, options: any[], setChange: any, setClearSelectApi?: any}> = (props) => {
    const {placeholder, name, options, setChange, setClearSelectApi} = props;
    const [isClearable, setIsClearable] = useState(true);
    const [isSearchable, setIsSearchable] = useState(true);
    const [isLoading, setIsLoading] = useState(false);

    const handleChange = (e: ChangeEvent<HTMLSelectElement>) => {
        setChange(name, e);
    }

    const handleMenuOpen = () => {
        if (setClearSelectApi != undefined){
            setClearSelectApi(name)
        }
    }

    return (
        <>
            <Select
                className="basic-single"
                classNamePrefix="select"
                isLoading={isLoading}
                isClearable={isClearable}
                isSearchable={isSearchable}
                name={name}
                options={options}
                onChange={handleChange}
                onMenuOpen={handleMenuOpen}
                required
                placeholder={placeholder}

            />
        </>
    );
};


export default SendSelect