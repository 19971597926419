import React, {useState, useEffect } from 'react';
import axios from 'axios';
import SubscriberStatus from "./SubscriberStatus";
import { useSelector } from "react-redux";
import { User } from "../models/user";
import {Position} from "../models/position";
import {OpenOrder} from "../models/openOrder";
import {Link} from 'react-router-dom';

interface UserType {
    user: User;
}

interface UserTypeRedux {
    user: UserType;
}

interface TradingPlatform {
    platform: string;
}

interface Subscriber {
    id: number;
    serviceName: string;
    client: string;
    client_id: number;
    api_keys: number;
}

const MonitoringSubscribers: React.FC<TradingPlatform> = (props) => {
    const {platform} = props;
    const trading_platform = platform.toLowerCase().split(' ')[0]
    const [error, setError] = useState('');
    const [tradersBalance, setTradersBalance] = useState(0);
    const [showSubscribers, setShowSubscribers] = useState([]);
    const [openOrdersTrader, setOpenOrdersTrader] = useState([]);
    const [openPositionsTrader, setOpenPositionsTrader] = useState([]);
    const user: User = useSelector((state: UserTypeRedux) => state.user.user)


    const getMonitoring = async (platform: string) => {
        if (platform && user.role) {
            const trader_id = user.id
            try {
                const response = await axios.get(`${platform}/futures/monitoring/trader/${trader_id}`);
//                 console.log(response)
                if (response.data.code){
                    setError(response.data.msg)
                } else {
                    setShowSubscribers(response.data)
                }

            } catch (e:any) {
                setError(e.message)
                console.log(e.message)
          }

        }
    }

    const getTraderOrders = async (platform: string) => {

        try {
            const response = await axios.get(`${platform}/futures/all_open_orders/`);
            if (response.data.openOrders.msg !== undefined){
                if (response.data.openOrders.msg.includes("API-key format invalid")) {
                    setError('API-key format invalid')
                }
            }
             else {
                setOpenOrdersTrader(response.data.openOrders);
            }
        } catch (e:any) {
                console.log("Error:", {e})
                if (e.message.includes("403")){
                    setError('403 Forbidden');

                } else {
                    setError(e.message);
                }
         }

    }

    const getTraderPositions = async (platform: string) => {
        if (platform) {
            try {
                const response = await axios.get(`${platform}/futures/account_info/BTCUSDT/`);
                if (response.data.code){
                    setError(response.data.msg)
//                     const pos: any = [{symbol: '1000BONKUSDT', entryPrice: 0.023087, side: 'Sell', takeProfit: 0, stopLoss: 0, initialMargin: 0, unrealizedProfit: 0, leverage: 10, isolated: true, positionAmt: 0, notional: 0, isolatedWallet: 0, margin: '0'},]
//                     setOpenPositionsTrader(pos);
                } else {
                    setOpenPositionsTrader(response.data.positions);
                    setTradersBalance(response.data.availableBalance);
                }

            } catch (e:any) {
                setError(e.message)
                console.log(e.message)
            }

        }
    }

    const getTraderStatus = async (platform: string) => {
        await getTraderOrders(platform)
        await getTraderPositions(platform)
    }

    useEffect(()=> {
        if (platform) {
            getMonitoring(platform);
            getTraderStatus(trading_platform)
        }
    },[platform]);

    if (error.includes("403")) {
        return (
            <div className="form_row">
                 <a href="/login">Login</a> or <a href="/register">Register Now</a> to trade
            </div>
        )
    }

    return (
    <>
        <p></p>
        <b>Monitoring subscribers</b> <span className='green'>TRADER {user.name} </span>
        <Link to="/futures/bybit/BTCUSDT/" >{platform}</Link>
        <hr/>

        <div className="table-responsive small">
                    {showSubscribers && (
                    <table className="table table-striped table-sm">
                    <thead>
                        <tr>
                          <th scope="col">Client</th>
                          <th scope="col">Service</th>
                          <th scope="col">Total balance</th>
                          <th scope="col">Available balance</th>
                          <th scope="col">In positions</th>
                          <th scope="col">Unrealised PNL</th>
                          <th scope="col">CumRealised PNL</th>
                          <th scope="col">Synchronization status</th>
                        </tr>
                    </thead>
                    <tbody>
                        {showSubscribers && (showSubscribers.map(
                                (subscriber: Subscriber) => {
                                    return (
                                        <SubscriberStatus
                                            id = {subscriber.id}
                                            client = {subscriber.client}
                                            client_id = {subscriber.client_id}
                                            serviceName = {subscriber.serviceName}
                                            platform = {platform}
                                            openOrdersTrader = {openOrdersTrader}
                                            openPositionsTrader = {openPositionsTrader}
                                            tradersBalance = {tradersBalance}
                                        />
                                    )
                                }
                            ))}
                    </tbody>
                    </table>
                )}

            </div>

    </>

    )
}

export default MonitoringSubscribers;