import React, {Component} from 'react';
import Wrapper from './Wrapper';
import {connect} from "react-redux";
import {User} from "../models/user";

class Settings extends Component<{user: User}> {

    render() {
        const {name, email} = this.props.user;
        return (
            <Wrapper>
                <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                    <h1 className="h2">Settings</h1>

                </div>
                Name: {name}<br/>
                Email: {email}

            </Wrapper>
        )
    }
}

// @ts-ignore
export default connect(state => ({user: state.user.user}))(Settings);