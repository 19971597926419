import React, {useState} from 'react';
import { useDispatch, useSelector} from "react-redux";
import {Link} from 'react-router-dom';
import {Position} from "../models/position";
import {OpenOrder} from "../models/openOrder";
import CancelOpenOrder from "./CancelOpenOrder";
import CancelOpenOrdersAll from "./CancelOpenOrdersAll";
import {setOpenOrders} from "../redux/actions/setOpenOrdersAction";
import ClosePositionBy from "./ClosePositionBy";
import EditTPSL from "./EditTPSL";

interface PositionsType {
    positions: Position[];
}

interface OpenOrdersType {
    open_orders: OpenOrder[];
}

interface PositionsTypeRedux {
    positions: PositionsType;
    open_orders: OpenOrdersType;
}

interface TradingPlatform {
    platform: string;
    availableBalance: number;
    setNewOpenOrder: any;
    updatePosition: any;
}

const Positions: React.FC<TradingPlatform> = (props) => {
    const {platform, availableBalance, setNewOpenOrder, updatePosition} = props;
    const [error, setError] = useState('');
    const [showOpenPositions, setShowOpenPositions] = useState(true);
    const [showOpenOrders, setShowOpenOrders] = useState(false);
    const dispatch = useDispatch();

    const positions: Position[] = useSelector((state: PositionsTypeRedux) => state.positions.positions)
    const open_orders: OpenOrder[] = useSelector((state: PositionsTypeRedux) => state.open_orders.open_orders)

    const handleCancelOrder = (orderId: string) => {
        const op_orders = open_orders.filter((o: OpenOrder) => o.orderId !== orderId)
        dispatch(setOpenOrders(op_orders));
    }

    const _open_positions= () => {
        setShowOpenPositions(true);
        setShowOpenOrders(false);
    }
    const _open_orders= () => {
        setShowOpenPositions(false);
        setShowOpenOrders(true);
    }

    const action = (platform: string, symbol: string, orderId: string) => {
        return (
            <CancelOpenOrder
                orderId={orderId}
                endpoint={`${platform}/futures/order/${symbol}`}
                handleCancelOrder={handleCancelOrder}
            />
        )
    }
    const cancel_all = () => {
        return (
            <CancelOpenOrdersAll
                open_orders={open_orders}
                endpoint={`${platform}/futures/cancel_orders_all`}
                handleCancelOrdersAll={handleCancelOrdersAll}
            />
        )
    }
    const handleCancelOrdersAll = () => {
//         const op_orders = open_orders.filter((o: OpenOrder) => o.orderId !== orderId)
        dispatch(setOpenOrders([]));
    }

    const dateTime = (time: number) => {
        return new Intl.DateTimeFormat('en-US', {year: 'numeric', month: '2-digit',day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: false,}).format(time)
    }
    const margin = (position:Position) => {
        if (position.isolated) {
            return <span> {position.isolatedWallet} </span>
        }
        else {
            return <span> {position.initialMargin} </span>
        }
    }

    if (error.includes("403")) {
        return (
            <div className="form_row">
                 <a href="/login">Login</a> or <a href="/register">Register Now</a> to trade
            </div>
        )
    }
    return (
        <>
        <hr/>
        <ul className="nav nav-tabs">
            <li className="nav-item">
                <Link className="nav-link" aria-current="page" to="#" onClick={_open_positions}>Positions ({positions ? positions.length : 0})</Link>
            </li>
            <li className="nav-item">
                <Link className="nav-link" to="#" onClick={_open_orders}>Open Orders ({open_orders ? open_orders.length : 0})</Link>
            </li>
        </ul>

        <div className="table-responsive small">
                {showOpenPositions && (
                <table className="table table-striped table-sm">
                <thead>
                    <tr>
                      <th scope="col">Symbol</th>
                      <th scope="col">Size</th>
                      <th scope="col">Entry Price</th>
                      <th scope="col">Liq Price</th>
                      <th scope="col">In Margin</th>
                      <th scope="col">PNL</th>
                      <th scope="col">TP/SL</th>
                      <th scope="col">Close By</th>
                    </tr>
                </thead>
                <tbody>
                    {positions && (positions.map(
                            (position: Position) => {
                                return (
                                    <tr key={position.symbol}>
                                        <td>
                                            <Link to={`/futures/${platform}/`+position.symbol}>{position.symbol}</Link>&nbsp;
                                            {position.leverage}x <br/></td>
                                        <td><span className={(position.side==='Sell') ? 'red':'green'}>{position.positionAmt}  {position.symbol.replace('USDT','')}<br/>{position.national} USDT</span></td>
                                        <td>{position.entryPrice}</td>
                                        <td><span className='orange'>{(position.liqPrice) ? position.liqPrice: '--'}</span></td>
                                        <td>{margin(position)}</td>
                                        <td><span className={(position.unrealizedProfit<0) ? 'red':'green'}>{position.unrealizedProfit} USDT</span></td>
                                        <td>
                                            <EditTPSL
                                                platform={platform}
//                                                 order_id={position.orderId}
                                                symbol={position.symbol}
                                                takeProfit={position.takeProfit}
                                                stopLoss={position.stopLoss}
                                                updatePosition={updatePosition}
                                                is_position={true}
                                            />
                                        </td>
                                        <td>
                                            <ClosePositionBy
                                                platform={platform}
                                                position={position}
                                                availableBalance={availableBalance}
                                                setNewOpenOrder={setNewOpenOrder}
                                                updatePosition={updatePosition}
                                                setError={setError}
                                                error={error}
                                            />
                                        </td>
                                    </tr>
                                )
                            }
                        ))}
                </tbody>
                </table>
            )}
            {showOpenOrders && (
                <table className="table table-striped table-sm">
                <thead>
                    <tr>
                      <th scope="col">Time</th>
                      <th scope="col">Symbol</th>
                      <th scope="col">Type</th>
                      <th scope="col">Side</th>
                      <th scope="col">Price</th>
                      <th scope="col">Amount</th>
                      <th scope="col">Reduce-Only</th>
                      <th scope="col">TP</th>
                      <th scope="col">SL</th>
                      <th scope="col">Action {cancel_all()}</th>
                    </tr>
                </thead>
                <tbody>
                    {(open_orders && platform) && (open_orders.sort((a,b) => b['time']-a['time']).map(
                            (openOrder: OpenOrder) => {
                                return (
                                    <tr key={openOrder.orderId}>
                                        <td>{dateTime(openOrder.time)}</td>
                                        <td>
                                            <Link to={`/futures/${platform}/`+openOrder.symbol}>{openOrder.symbol}</Link>
                                        </td>
                                        <td>{openOrder.orderType}</td>
                                        <td>{openOrder.side}</td>
                                        <td>{openOrder.price}</td>
                                        <td>{(openOrder.origQty ? openOrder.origQty : openOrder.qty)}</td>
                                        <td>{openOrder.reduceOnly ? <span>True</span> : <span>No</span>}</td>
                                        <td>{openOrder.takeProfit}</td>
                                        <td>{openOrder.stopLoss}</td>
                                        <td className="pointer">
                                            {action(platform, openOrder.symbol, openOrder.orderId)}
                                        </td>
                                    </tr>
                                )
                            }
                        ))}
                </tbody>
                </table>
            )}
        </div>
        </>
    )
}

export default Positions;