import React, {Component} from 'react';
import Wrapper from '../components/Wrapper';
import {connect} from "react-redux";
import {User} from "../models/user";
import MonitoringSubscribers from '../components/MonitoringSubscribers';

class Dashboard extends Component<{user: User}> {
    state = {
    }
    render() {
        if (!this.props.user.role) {
            return (
                <Wrapper>
                    <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                        <h1 className="h2">Dashboard</h1>

                    </div>

                </Wrapper>
            )
        }
        return (
            <Wrapper>
                <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                    <h1 className="h2">Dashboard</h1>

                </div>
                {(this.props.user.role === 1) && (
                    <MonitoringSubscribers
                        platform = {'Bybit Futures'}
                 />
                )}

            </Wrapper>
        )
    }
}
// @ts-ignore
export default connect(state => ({user: state.user.user})) (Dashboard);